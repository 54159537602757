import { useMutation } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { GetPresignedUrl, PresignedUrl } from '@/api/types';

interface GetUserUploadsSignedUrlsParams {
  userId: string;
  files: GetPresignedUrl[];
}

export const useGetUserUploadsSignedUrls = () => {
  const { getClient } = useApiClient();

  return useMutation({
    mutationFn: async ({ files, userId }: GetUserUploadsSignedUrlsParams) => {
      const client = await getClient();

      return client
        .patch<{ files: PresignedUrl[] }>(`/users/${userId}/uploads`, {
          files,
        })
        .then((response) => response.data);
    },
  });
};
