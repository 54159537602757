import { CalendarDateRangeIcon, CheckCircleIcon } from '@heroicons/react/16/solid';

import Miaven from '@/assets/maiven.svg';
import CollapsibleList from '@/components/CollapsibleList';
import ImageTextLink from '@/components/ImageTextLink';
import Text from '@/components/Typography';
import BottomBar from '@/layouts/ApplicationLayout/BottomBar';

const Activity = () => {
  return (
    <div className='flex flex-col gap-4 p-4'>
      <div className='flex flex-col gap-[10px] p-4'>
        <Text className='text-2xl font-bold'>My Activity</Text>
        <Text>Your active enrollments, installs, and purchases and completed items.</Text>
      </div>
      <div className='flex flex-col gap-[10px]'>
        <CollapsibleList Icon={CalendarDateRangeIcon} status='In Progress' />
        <ImageTextLink
          image={Miaven}
          link='/dashboard/opportunities'
          text='Head to the opportunities page to start saving money and energy!'
        />
      </div>
      <CollapsibleList Icon={CheckCircleIcon} status='Completed' />
      <BottomBar />
    </div>
  );
};

export default Activity;
