import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { ExpandableRichTextBlockStoryblok } from '@maivenenergy/storyblok';
import { useState } from 'react';

import RichtextDisplay from '../../storyblokComponents/RichtextDisplay';

import Button from '@/components/Button';
import ModalDisplay from '@/components/ModalDisplay';
import Text from '@/components/Typography';

interface ExpandableRichTextBlockProps {
  blok: ExpandableRichTextBlockStoryblok;
}

const ExpandableRichTextBlock = ({ blok }: ExpandableRichTextBlockProps) => {
  const [showModal, setShowModal] = useState(false);

  const closeModalHandle = () => {
    setShowModal(false);
  };

  return (
    <div className='flex flex-col gap-2'>
      {blok.summary && <Text className='line-clamp-3 text-base'>{blok.summary}</Text>}
      <Button
        className='flex w-fit cursor-pointer items-center text-xs text-gray-600'
        size='xs'
        buttonType='normal'
        buttonStyle='light'
        onClick={() => {
          setShowModal((prev) => !prev);
        }}
      >
        {!showModal && (
          <>
            <PlusIcon className='mr-1 size-3' />
            Read More
          </>
        )}
      </Button>
      <ModalDisplay showModal={showModal} closeModalHandle={closeModalHandle}>
        <div className='flex flex-col px-4 py-2'>
          {blok.text && <RichtextDisplay richText={blok.text} className={`${showModal && 'line-clamp-3'}`} />}
        </div>
      </ModalDisplay>
    </div>
  );
};

export default ExpandableRichTextBlock;
