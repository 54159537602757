import { EnergyUsageBlockStoryblok } from '@maivenenergy/storyblok';
import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

interface EnergyUsageBlockProps {
  blok: EnergyUsageBlockStoryblok;
}

const EnergyUsageBlock: React.FC<EnergyUsageBlockProps> = ({ blok }) => {
  return (
    <div className='flex flex-row'>
      {blok.energy_usage_items?.map((item) => {
        return <StoryblokComponent blok={item} key={item._uid} />;
      })}
    </div>
  );
};

export default EnergyUsageBlock;
