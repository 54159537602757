import { NextStepsBlockStoryblok } from '@maivenenergy/storyblok';
import { StoryblokComponent } from '@storyblok/react';
interface NextStepsBlockProps {
  blok: NextStepsBlockStoryblok;
}

const NextStepsBlock = ({ blok }: NextStepsBlockProps) => {
  return (
    <div>
      {blok.steps?.map((step, index: number) => {
        const blok = {
          ...step,
          index: index,
        };
        return typeof step === 'string' ? null : <StoryblokComponent blok={blok} key={step._uid} />;
      })}
    </div>
  );
};

export default NextStepsBlock;
