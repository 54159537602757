import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { useApiClient } from '@/api/hooks/useApiClient';
import { AssessmentRunStatus } from '@/api/types';

// Base response from API
interface AssessmentRunStatusResponse {
  status: AssessmentRunStatus;
}

// Extended type including our timedOut flag
interface AssessmentRunStatusWithTimeout extends AssessmentRunStatusResponse {
  timedOut: boolean;
}

export function useGetAssessmentRunStatus(assessmentId: string) {
  const startTime = useRef(Date.now());
  const TIMEOUT_DURATION = 60000; // 60 seconds in milliseconds

  const { getClient } = useApiClient();
  return useQuery<AssessmentRunStatusResponse, Error, AssessmentRunStatusWithTimeout>({
    queryKey: ['assessment-run-status', assessmentId],
    queryFn: async () => {
      const client = await getClient();

      const response = await client.get<AssessmentRunStatusResponse>(`/assessments/${assessmentId}/run-status`);

      return response.data;
    },
    refetchInterval: (response) => {
      const timeElapsed = Date.now() - startTime.current;

      // Stop polling if we've exceeded TIMEOUT_DURATION or the assessment has completed
      if (timeElapsed >= TIMEOUT_DURATION || response?.state?.data?.status === AssessmentRunStatus.FINISHED) {
        return false;
      }

      return 1000; // Continue polling every second
    },
    retry: false, // Don't retry on error
    gcTime: 0, // Immediately garbage collect
    staleTime: 0, // Always consider data stale
    refetchOnWindowFocus: false,
    select: (data: AssessmentRunStatusResponse): AssessmentRunStatusWithTimeout => {
      const timeElapsed = Date.now() - startTime.current;
      return {
        status: data.status,
        timedOut: timeElapsed >= TIMEOUT_DURATION,
      };
    },
  });
}
