import { AssessmentQuestionInfoStoryblok } from '@maivenenergy/storyblok';

import { AssessmentResultType } from './Assessment';

export enum AssessmentQuestionType {
  IMAGE_UPLOAD = 'image_upload',
  DOCUMENT_UPLOAD = 'document_upload',
  MULTI_IMAGE_UPLOAD = 'multi_image_upload',
  MULTI_DOCUMENT_UPLOAD = 'multi_document_upload',
  MULTI_SELECT = 'multi_select',
  SINGLE_SELECT = 'single_select',
  SINGLE_SELECT_LOOKUP = 'single_select_lookup',
  FREE_FORM = 'free_form',
  FREE_FORM_LARGE = 'free_form_large',
  YEAR = 'year',
  NUMBER = 'number',
  MULTI_RADIO_GROUP = 'multi_radio_group',
  SINGLE_RADIO_GROUP = 'single_radio_group',
  DATE = 'date',
}

export enum AssessmentQuestionCategory {
  GENERAL = 'general',
  ACCOUNT = 'account',
  STRUCTURE = 'structural',
  BILLING = 'billing/usage',
  BUILDING = 'building',
  ROOF = 'roof',
  HVAC = 'hvac',
  PLUG_LOAD = 'plug load',
  VEHICLE = 'vehicle',
  DER = 'DER',
  POOL = 'pool',
  OTHER = 'other',
}

export enum AssessmentSubSectionType {
  SHARE_USAGE_DATA = 'share_usage_data',
}

export interface AssessmentResultValue {
  id: string;
  value: string;
  index?: number;
}

export interface AssessmentResult {
  id: string;
  value: string;
  type: AssessmentResultType;
  assessmentQuestionId: string;
  values: AssessmentResultValue[];
}

export interface AssessmentTemplateContentSectionCover {
  header: string;
  icon: string;
  subheader: string;
  body: string;
  matters_text?: string | JSX.Element;
}

export interface StoryblokAssessmentSubSection {
  header: string;
  description?: string;
  section_order?: number;
  section_type?: AssessmentSubSectionType;
  depends_on?: string;
  depends_on_value?: string;
  questions?: StoryblokAssessmentQuestion[];
  core_data_fields?: StoryblokAssessmentCoreDataField[];
  repeats_on?: string;
  info?: AssessmentQuestionInfoStoryblok[];
}

export interface StoryblokAssessmentSection {
  body: string;
  icon: string;
  matters_text: string;
  section_order: number; // TODO: get rid of section_order property
  section_header: string;
  sub_header: string;
  section_name: string;
  section_cover: AssessmentTemplateContentSectionCover;
  subsections: StoryblokAssessmentSubSection[];
}

export interface AssessmentTemplate {
  template_details: {
    terms_enabled: number;
    terms_version: string;
    display_carousel: number;
    theme: string;
  };
  template_sections: StoryblokAssessmentSection[];
}

export interface StoryblokAssessmentQuestionOption {
  name: string;
  display: string;
  value: string;
  image: { filename: string };
}

export interface StoryblokAssessmentQuestion {
  name: string;
  type: AssessmentQuestionType;
  label: string;
  display: string;
  question_id: string;
  question_order: number;
  depends_on?: string[];
  depends_on_value?: string | string[];
  filter_on?: string;
  lookup_column?: string;
  lookup_table?: string;
  helper_html?: string;
  question_group?: string;
  header_helper_text?: string;
  footer_helper_text?: string;
  required?: boolean;
  options: StoryblokAssessmentQuestionOption[];
  info?: AssessmentQuestionInfoStoryblok[];
}

export interface StoryblokAssessmentQuestionGroup {
  name: string;
  display: string;
  questions: StoryblokAssessmentQuestion[];
}

export interface StoryblokAssessmentCoreDataField {
  data_field_name: string;
}

export interface StoryblokAssessmentTemplateContent {
  display_carousel: boolean;
  sections: StoryblokAssessmentSection[];
  terms_enabled: boolean;
  terms_version: string;
}

export interface StoryblokAssessmentTemplate {
  terms_enabled: boolean;
  terms_version: string;
  display_carousel: boolean;
  content: StoryblokAssessmentTemplateContent;
}

export enum AssessmentStatus {
  STARTED = 'started',
  COMPLETE = 'completed',
  DEACTIVATED = 'deactivated',
}

export interface AssessmentDetails {
  userId: string;
  propertyId: string;
  assessmentId: string;
  assessmentStatus: AssessmentStatus;
  affiliate: {
    id: string;
    programName: string;
    contentTag: 'ZEEP' | 'EPMH';
    programContactEmail?: string;
    programContactPhone?: string;
    programContactPhoneDisplay?: string;
  };
  questions: Record<string, StoryblokAssessmentQuestion>;
  results: Record<string, AssessmentResult>;
  template: StoryblokAssessmentTemplate;
}

export interface ProgramParticipationAgreement {
  id: string;
  userServiceAccountId: string;
  ipAddress: string;
  acceptedAt: Date;
  affiliateId: string;
  structureProfileId: string;
  createdAt: Date;
  updatedAt: Date;
}
