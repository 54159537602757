import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { EnrollmentReviewStory } from '@/api/types';

export const useGetEnrollmentReviewStory = () => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: ['enrollment-review-story'] as const,
    queryFn: async () => {
      const client = await getClient();

      const response = await client.get<EnrollmentReviewStory>('/stories/enrollment-review');

      return response.data;
    },
  });
};
