import { FC, ReactNode } from 'react';

interface LoadingOverlayProps {
  children: ReactNode;
  isLoading: boolean;
}

const LoadingOverlay: FC<LoadingOverlayProps> = ({ children, isLoading }) => {
  return (
    <div className='relative'>
      {isLoading && (
        <div className='absolute inset-0 z-10 flex items-center justify-center bg-white/80'>
          <div className='size-12 animate-spin rounded-full bg-gradient-to-r from-[#6100EE] to-[#D56AFF] p-[2px]'>
            <div className='size-full rounded-full bg-white' />
          </div>
        </div>
      )}
      <div className={isLoading ? 'opacity-50' : ''}>{children}</div>
    </div>
  );
};

export default LoadingOverlay;
