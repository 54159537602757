import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { EnrollmentStepsStory } from '@/api/types';

export const useGetEnrollmentStepsStory = () => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: ['enrollment-steps-story'] as const,
    queryFn: async () => {
      const client = await getClient();

      const response = await client.get<EnrollmentStepsStory>('/stories/enrollment-steps');

      return response.data;
    },
  });
};
