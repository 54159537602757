import React, { useEffect, useState } from 'react';

import { SHARE_USAGE_DATA_SUBSECTION, UTILITIES_INFORMATION_SECTION } from './constants';

import Button from '@/components/Button';
import MaivenLogo from '@/components/MaivenLogo';
import { useUserProfileContext } from '@/contexts/UserProfileContext';

// TODO (Aimee):
// - Green Button Logo when files have been secured

export const OAUTH_REDIRECT_PARAM = 'oAuthRedirect';

const ShareUsageData: React.FC<{}> = () => {
  const { profile } = useUserProfileContext();

  const [sharedData, setSharedData] = useState(false);

  useEffect(() => {
    if (profile.shared_usage_data) {
      setSharedData(true);
    }
  }, [profile]);

  const handleAuthorizeClick = async () => {
    const clientId = import.meta.env.VITE_PGE_CLIENT_ID;
    const currentSectionParam = encodeURIComponent(UTILITIES_INFORMATION_SECTION);
    const currentSubsectionParam = encodeURIComponent(SHARE_USAGE_DATA_SUBSECTION);
    const oAuthRedirectUrl = encodeURIComponent(
      `${window.location.origin}/assessment?currentSection=${currentSectionParam}&currentSubsection=${currentSubsectionParam}&oAuthRedirect=true`
    );

    const fullHref = `https://${import.meta.env.VITE_BACKEND_DOMAIN}/oauth?client_id=${clientId}&user_id=${profile.id}&redirect_uri=${oAuthRedirectUrl}`;
    window.location.href = fullHref;
  };

  return sharedData ? (
    <div className='mx-10 mt-10 text-center'>
      <p className='mt-4 text-base'>You have successfully shared your usage data.</p>
    </div>
  ) : (
    <div className='mx-10 flex flex-col items-center gap-4 text-center'>
      <MaivenLogo variant='avatar' color='gradientDark' />
      <div className='mt-3'>
        <h1 className='text-2xl font-bold'>Sync Usage Data</h1>
        <p className='mt-4 text-base'>You can authorize your utility provider to share your usage data with Maiven.</p>
        <Button className='mt-6' buttonStyle='accent-1' onClick={handleAuthorizeClick}>
          Authorize Share My Data
        </Button>
      </div>
      {/* <div className='mt-3'>
        <p>Powered by</p>
        <p>Green Button Logo</p>
      </div> */}
      <p className='mt-3 text-base font-bold'>You can also skip this section and enter your utility info manually.</p>
    </div>
  );
};

export default ShareUsageData;
