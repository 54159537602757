import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useState } from 'react';

import Button, { ButtonProps } from '@/components/Button';
import Text from '@/components/Typography';

interface ModalProps {
  title: string;
  primaryActionLabel: string;
  primaryActionProps: ButtonProps;
  secondaryActionLabel?: string;
  secondaryActionProps?: ButtonProps;
  triggerButtonContent: JSX.Element;
  triggerButtonProps?: ButtonProps;
  action: 'warning';
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  action,
  children,
  primaryActionLabel,
  primaryActionProps,
  secondaryActionLabel,
  secondaryActionProps,
  title,
  triggerButtonContent,
  triggerButtonProps,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {triggerButtonProps ? (
        <Button
          {...triggerButtonProps}
          onClick={(e) => {
            try {
              triggerButtonProps.onClick?.(e);
            } catch {
              // no-op
            }

            setIsOpen(true);
          }}
        >
          {triggerButtonContent}
        </Button>
      ) : (
        <button
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {triggerButtonContent}
        </button>
      )}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className='relative z-50'>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop className='fixed inset-0 bg-gray-900/40' />

        {/* Full-screen container to center the panel */}
        <div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
          {/* The actual dialog panel  */}
          <DialogPanel className='flex max-w-md grow gap-4 rounded-2xl bg-white p-6'>
            {/* Action */}
            <div>
              <span className='relative block size-12 rounded-full bg-orange-100'>
                <ExclamationTriangleIcon className='absolute left-1/2 top-1/2 size-6 -translate-x-1/2 -translate-y-1/2 text-orange-600' />
              </span>
            </div>

            {/* Content */}
            <div className='flex grow flex-col gap-6'>
              <div className='flex flex-col gap-2'>
                <DialogTitle as={Fragment}>
                  <Text size='xl' className='font-semibold'>
                    {title}
                  </Text>
                </DialogTitle>
                <Text color='secondary' size='base'>
                  {children}
                </Text>
              </div>

              {/* Actions */}
              <div className='flex gap-3'>
                <Button
                  className='bg-orange-600 px-6 text-white hover:bg-orange-700'
                  {...primaryActionProps}
                  onClick={(e) => {
                    primaryActionProps.onClick?.(e);
                    setIsOpen(false);
                  }}
                >
                  {primaryActionLabel}
                </Button>
                {secondaryActionLabel && (
                  <Button
                    className='border border-gray-300 bg-white px-6 text-gray-900 hover:bg-gray-50 hover:text-white'
                    {...secondaryActionProps}
                    onClick={(e) => {
                      secondaryActionProps?.onClick?.(e);
                      setIsOpen(false);
                    }}
                  >
                    {secondaryActionLabel}
                  </Button>
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default Modal;
