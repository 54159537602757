import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { forwardRef, Dispatch, InputHTMLAttributes, SetStateAction } from 'react';

import Checkbox from '@/components/Checkbox';
import FormFieldLabel from '@/components/FormFieldLabel';
import Text from '@/components/Typography';

export interface CheckboxItem<TValue extends string | number = string> {
  label: string;
  value: TValue;
}

interface CheckboxesProps<TValue extends string | number = string> extends InputHTMLAttributes<HTMLInputElement> {
  ['data-id']: string;
  label?: string;
  items: CheckboxItem<TValue>[];
  values: { id: string; value: TValue }[];
  errors?: Record<string, unknown>;
  onChecked: (value: TValue) => void;
  onUnChecked: (idx: number) => void;
  hasinfo?: boolean;
  onClickInfo?: Dispatch<SetStateAction<boolean>>;
}

const Checkboxes = forwardRef<HTMLInputElement, CheckboxesProps>(
  (
    {
      items,
      'data-id': dataId,
      label,
      values,
      onChecked,
      onUnChecked,
      errors = {},
      name,
      hasinfo,
      onClickInfo,
      ...props
    },
    _ref
  ) => {
    const hasError = !!errors[name ?? ''];

    return (
      <fieldset>
        <FormFieldLabel data-id={`${dataId}-field-label`} label={label} hasInfo={hasinfo} onClickInfo={onClickInfo} />
        <div
          className={clsx(
            'flex flex-col justify-center gap-2',
            label && 'mt-4',
            hasError && 'rounded-lg p-2 outline outline-2 outline-orange-700'
          )}
        >
          {items.map((item, idx) => {
            const valueIdx = values.findIndex((v) => v.value === item.value);

            return (
              <Checkbox
                key={item.label}
                label={item.label}
                value={item.value}
                data-id={`${dataId}-${idx}`}
                checked={valueIdx >= 0}
                disabled={props.disabled}
                onChange={(e) => {
                  if (e.target.checked) {
                    onChecked(item.value);

                    return;
                  }

                  if (typeof valueIdx === 'number') {
                    onUnChecked(valueIdx);
                  }
                }}
              />
            );
          })}

          {hasError && (
            <div className='absolute right-3 top-3'>
              <ExclamationCircleIcon aria-hidden='true' className='size-5 text-orange-700' />
            </div>
          )}
        </div>

        {name && errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text id={`${dataId}-error`} size='sm' className='mt-2 text-orange-700'>
                {message}
              </Text>
            )}
          />
        )}
      </fieldset>
    );
  }
);

Checkboxes.displayName = 'Checkboxes';

export default Checkboxes;
