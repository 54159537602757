import React, { SVGProps, FC } from 'react';

export interface SVGIconProps extends SVGProps<SVGSVGElement> {
  width?: number | string;
  height?: number | string;
  viewBox?: string;
  color?: string;
  className?: string;
}

const SVGIcon: FC<SVGIconProps> = ({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  className = '',
  children,
  ...props
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox={viewBox}
      fill='none'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className={`icon ${className}`.trim()}
      {...props}
    >
      {children}
    </svg>
  );
};

export default SVGIcon;
