import MaivenInfo from './MaivenInfo';

import UserProfilePhoto from '@/assets/user-circle.svg';
import ProfileFieldDisplay from '@/components/ProfileFieldDisplay';
import Text from '@/components/Typography';
import { useUserProfileContext } from '@/contexts/UserProfileContext';
import BottomBar from '@/layouts/ApplicationLayout/BottomBar';

const Profile = () => {
  const { profile } = useUserProfileContext();

  return (
    <div className='flex flex-col gap-4 p-4'>
      <div className='p-4 pt-6'>
        <Text className='text-xl font-bold leading-none'>Profile</Text>
      </div>
      <div className='flex flex-col gap-4 p-4 text-sm'>
        <img
          src={profile.profile_photo_url ? profile.profile_photo_url : UserProfilePhoto}
          alt={'Avatar'}
          className='size-10 rounded-full'
        />
        <ProfileFieldDisplay label='First Name' value={profile.first_name}></ProfileFieldDisplay>
        <ProfileFieldDisplay label='Last Name' value={profile.last_name}></ProfileFieldDisplay>
        <ProfileFieldDisplay label='Email' value={profile.email}></ProfileFieldDisplay>
        {profile.properties.map((property, index) => (
          <ProfileFieldDisplay key={index} label='Your Home'>
            {
              <>
                <div className='flex flex-row gap-1 text-gray-600'>
                  <Text>{property.address.zip}</Text>
                  <Text>{property.address.address1}</Text>
                </div>
                <Text className='text-gray-600'>{property.address.state}</Text>
              </>
            }
          </ProfileFieldDisplay>
        ))}
      </div>
      <div className='p-4'>
        <MaivenInfo />
      </div>
      <BottomBar />
    </div>
  );
};

export default Profile;
