import { ScopeOfWorkIncentive, ScopeOfWorkMeasure } from '@/api';

interface CostItem {
  label: string;
  amount: number;
  indent?: boolean;
}

interface CostSavingsTableProps {
  header?: string;
  measures: ScopeOfWorkMeasure[];
  incentives: ScopeOfWorkIncentive[];
}

const CostSavingsTable: React.FC<CostSavingsTableProps> = ({ header = 'Incentive Source', measures, incentives }) => {
  const formatCurrency = (amount: number) => {
    const formattedAmount = Math.abs(amount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `$${formattedAmount}`;
  };

  const generateCostItems = (): CostItem[] => {
    const items: CostItem[] = [];

    measures.forEach((measure) => {
      items.push({
        label: `${measure.simpleMeasure}`,
        amount: measure.costUsd,
      });

      // Find and add associated incentives, filtering out zero values
      const measureIncentives = incentives.filter(
        (inc) => inc.measure === measure.simpleMeasure && inc.coverageUsd > 0
      );

      measureIncentives.forEach((inc) => {
        items.push({
          label: `${inc.source}`,
          amount: -inc.coverageUsd, // Keep negative for internal calculations, but display will be different
          indent: true,
        });
      });
    });

    // Calculate totals
    const measureTotal = measures.reduce((sum, m) => sum + m.costUsd, 0);
    const incentivesTotal = incentives.reduce((sum, i) => sum + i.coverageUsd, 0);
    const subTotal = Math.max(0, measureTotal - incentivesTotal);

    // Add summary rows
    items.push({ label: 'Measure Total', amount: measureTotal });
    items.push({ label: 'Incentives', amount: incentivesTotal });
    items.push({ label: 'Sub Total', amount: subTotal });

    return items;
  };

  const items = generateCostItems();

  return (
    <div className='mt-4 w-full max-w-2xl'>
      <table className='w-full'>
        <thead>
          <tr className='mt-4 bg-gray-200'>
            <th className='p-2 text-left text-sm font-semibold'>Incentive Source</th>
            <th className='p-2 text-right text-sm font-semibold'>Cost &amp; Savings</th>
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-100'>
          {items.map((item, index) => (
            <tr key={index} className={`${item.indent ? 'bg-transparent' : 'bg-gray-50'}`}>
              <td className={`p-2 text-sm ${item.indent ? 'pl-4' : 'font-semibold'}`}>{item.label}</td>
              <td className={`p-2 text-right text-sm ${!item.indent ? 'font-semibold' : ''}`}>
                {item.amount < 0 ? `(${formatCurrency(item.amount)})` : formatCurrency(item.amount)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CostSavingsTable;
