import React from 'react';

import AssessmentContext, { AssessmentContextValue } from './context';

import { useGetAssessmentDetails, useGetTermsOfService } from '@/api';
import Text from '@/components/Typography';
import { logger } from '@/lib/Logger';

const AssessmentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    isLoading: isLoadingAssessmentDetails,
    data: assessmentDetails,
    error: assessmentDetailsError,
  } = useGetAssessmentDetails();
  const areTermsEnabled = !!assessmentDetails?.template.content.terms_enabled;
  const {
    data: termsOfService,
    isLoading: isLoadingTermsOfService,
    error: termsOfServiceError,
  } = useGetTermsOfService(
    areTermsEnabled
      ? {
          enabled: true,
          version: assessmentDetails.template.content.terms_version,
          assessmentId: assessmentDetails.assessmentId,
        }
      : { enabled: false }
  );

  const isLoading = isLoadingAssessmentDetails || isLoadingTermsOfService;
  const error = assessmentDetailsError ?? termsOfServiceError;
  const errorState = !assessmentDetails || (areTermsEnabled && !termsOfService) || !!error;

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (errorState) {
    logger.error('Failed to fetch assessment details:', { error });

    return <Text>Something wrong happened. Try again</Text>;
  }

  const contextValue: AssessmentContextValue = {
    details: assessmentDetails,
    termsOfService,
  };

  return <AssessmentContext.Provider value={contextValue}>{children}</AssessmentContext.Provider>;
};

export default AssessmentProvider;
