import React, { ErrorInfo } from 'react';

import { logger } from '@/lib/Logger';

type Props = React.PropsWithChildren;

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    let errInfo = 'Not set';

    try {
      errInfo = JSON.stringify(errorInfo);
    } catch {
      /* empty */
    }
    logger.error('Unhandled error', { error, info: errInfo });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
