import { useState } from 'react';

import { useEnrollment } from '.';

import { useGetEnrollmentSignStory } from '@/api';
import Checkbox from '@/components/Checkbox';
import LoadingOverlay from '@/components/LoadingOverlay';
import Text from '@/components/Typography';
import { useUserProfileContext } from '@/contexts/UserProfileContext';
import { logger } from '@/lib/Logger';

interface EnrollmentSignProps {
  isLoading: boolean;
}

const EnrollmentSign = ({ isLoading }: EnrollmentSignProps) => {
  const { profile } = useUserProfileContext();
  const { programName } = useEnrollment();
  const { data, isLoading: isFetchingStory, error } = useGetEnrollmentSignStory();
  const [landlordChecked, setLandlordChecked] = useState(false);

  const blok = data?.content;
  const errorState = !data || !blok || !profile.first_name || !profile.last_name || error;

  if (isFetchingStory) {
    return null;
  }

  if (errorState) {
    logger.error('Failed to fetch enrollment sign story', { error });
    return <Text>Something wrong happened. Try again</Text>;
  }

  const today = new Date().toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

  const fullName = `${profile.first_name} ${profile.last_name}`;

  return (
    <LoadingOverlay isLoading={isLoading}>
      <div className='flex flex-col gap-[10px] p-8'>
        <Text className='text-lg font-bold'>{programName}</Text>
        <Text className='text-2xl font-bold'>{blok.title}</Text>
        <Text className='text-xs'>{blok.description}</Text>
        <div className='mt-3'>
          <Text>{blok.name_input}</Text>
          <div className='w-full rounded bg-gray-100 p-1'>
            <Text>{fullName}</Text>
          </div>
        </div>
        <div className='mt-1'>
          <Text>{blok.date_input}</Text>
          <div className='w-full grow rounded bg-gray-100 p-1'>
            <Text>{today}</Text>
          </div>
        </div>
        <div className='my-6'>
          {blok.landlord_input ? (
            <Checkbox
              label={blok.landlord_input}
              data-id='landlord-owner-renter-disclaimer'
              name='landlord-owner-renter-disclaimer'
              checked={landlordChecked}
              onChange={(e) => {
                setLandlordChecked(e.target.checked);
              }}
              className='text-xs'
            />
          ) : null}
        </div>
        <Text>{blok.signature_input}</Text>
        <div className='w-full grow rounded bg-gray-100 p-1'>
          <Text>{fullName}</Text>
        </div>
        <div className='mt-2'>
          <Text className='text-xs'>{blok.disclaimer}</Text>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default EnrollmentSign;
