import { ResidentDashSectionStoryblok } from '@maivenenergy/storyblok';
import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import Text from '@/components/Typography';
import MeasureSummary from '@/pages/Dashboard/components/measures/MeasureSummary';
import PrerequisiteSummary from '@/pages/Dashboard/components/measures/PrerequisiteSummary';

interface ResidentDashSectionProps {
  blok: ResidentDashSectionStoryblok & { className?: string };
}

const specialSections: {
  [key: string]: React.ComponentType<ResidentDashSectionProps>;
} = {
  measure_summary: MeasureSummary,
  prerequisite_summary: PrerequisiteSummary,
};

const ResidentDashSection: React.FC<ResidentDashSectionProps> = ({ blok }) => {
  const sectionType = blok.type ?? '';
  const SectionComponent = specialSections[sectionType];
  const backgroundColor = blok.background_color ?? 'bg-white';

  if (sectionType && SectionComponent) {
    return <SectionComponent blok={blok} />;
  }
  return (
    <div className={`flex flex-col gap-4 p-6 ${blok.className || ''} ${backgroundColor}`}>
      {blok.separator_top && <hr className='border border-yellow-600' />}
      {blok.graphic?.filename && (
        <div className='h-36'>
          <img src={blok.graphic.filename} alt='program-summary' className='size-full rounded-lg object-cover' />
        </div>
      )}

      {blok.title && <Text className='text-lg font-bold'>{blok.title}</Text>}
      {blok.description && <Text>{blok.description}</Text>}

      {blok.related_items?.map((blok) => {
        return typeof blok === 'string' ? null : <StoryblokComponent blok={blok.content} key={blok.uuid} />;
      })}
      {/* {blok.type === 'last_report_details' && (
          <div className='flex flex-col p-4'>
            <div className='rounded-lg bg-gray-100 p-2 text-center'>Your report is being generated</div>
          </div>
        )} */}
    </div>
  );
};

export default ResidentDashSection;
