import Text from '@/components/Typography';

interface ProfileFieldDisplayProps {
  label?: string;
  value?: string;
}

const ProfileFieldDisplay: React.FC<React.PropsWithChildren<ProfileFieldDisplayProps>> = ({
  label,
  value,
  children,
}) => {
  return (
    <div className='flex flex-col'>
      <Text className='text-sm font-bold'>{label}</Text>
      <Text className='text-sm text-gray-600'>{value}</Text>
      {children}
    </div>
  );
};

export default ProfileFieldDisplay;
