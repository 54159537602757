import { MeasureSubcategoryStoryblok } from '@maivenenergy/storyblok';

import ImageTextModalDisplay from '@/components/ImageTextModalDisplay';

interface MeasureSubcategoryProps {
  blok: MeasureSubcategoryStoryblok;
}

const MeasureSubcategory = ({ blok }: MeasureSubcategoryProps) => {
  return (
    <div>
      <ImageTextModalDisplay
        modalImage={blok.graphic?.filename || ''}
        richText={blok.description || { type: 'doc', content: [] }}
        title={blok.title || ''}
        triggerText={blok.title || ''}
        subtitle=''
      />
    </div>
  );
};

export default MeasureSubcategory;
