import { useEnrollment } from '.';

import { useGetEnrollmentDisclaimerStory } from '@/api';
import EnrollmentDisclaimerImage from '@/assets/enrollment-disclaimer.svg';
import Text from '@/components/Typography';
import { logger } from '@/lib/Logger';
import RichtextDisplay from '@/storyblokComponents/RichtextDisplay';

const EnrollmentDisclaimer = () => {
  const { programName } = useEnrollment();
  const { data, isLoading, error } = useGetEnrollmentDisclaimerStory();
  const blok = data?.content;

  if (isLoading) {
    return null;
  }

  const errorState = !data || !blok || error;

  if (errorState) {
    logger.error('Failed to fetch dashboard stories', { error });
    return <Text>Something wrong happened. Try again</Text>;
  }

  return (
    <div className='flex flex-col gap-[10px] p-8'>
      <Text className='text-lg font-bold'>{programName}</Text>
      <div className='flex justify-center'>
        <img src={EnrollmentDisclaimerImage} alt='enrollment-disclaimer-image' className='w-52' />
      </div>
      <Text className='text-2xl font-bold'>{blok.highlight_text}</Text>
      {blok.description && <RichtextDisplay richText={blok.description} />}
    </div>
  );
};

export default EnrollmentDisclaimer;
