import { Navigate, Route, Routes } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import Assessment from './Assessment';
import AssessmentComplete from './AssessmentComplete';
import { Activity, Home, Opportunities, Profile, OpportunitiesDetails } from './Dashboard';
import EnrollmentScreen from './Enrollment';
import Login from './Login';
import Logout from './Logout';
import TermsOfService from './TermsOfService';

import RootLayout from '@/components/Layout/RootLayout';
import PrivateRoute from '@/components/PrivateRoute';
import Text from '@/components/Typography';
import AssessmentProvider from '@/contexts/AssessmentContext';
import UserProfileProvider from '@/contexts/UserProfileContext';
import ApplicationLayout from '@/layouts/ApplicationLayout';

const Pages = () => {
  return (
    <Routes>
      <Route path='/' element={<RootLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='logout' element={<Logout />} />

        <Route index element={<Navigate to='/terms' />} />

        <Route
          path='terms'
          element={
            <PrivateRoute>
              <AssessmentProvider>
                <TermsOfService />
              </AssessmentProvider>
            </PrivateRoute>
          }
        />

        <Route
          path='assessment'
          element={
            <PrivateRoute>
              <UserProfileProvider>
                <AssessmentProvider>
                  <Assessment />
                </AssessmentProvider>
              </UserProfileProvider>
            </PrivateRoute>
          }
        />

        <Route
          path='assessment-complete'
          element={
            <PrivateRoute>
              <UserProfileProvider>
                <AssessmentProvider>
                  <AssessmentComplete />
                </AssessmentProvider>
              </UserProfileProvider>
            </PrivateRoute>
          }
        />

        <Route
          path='dashboard'
          element={
            <PrivateRoute>
              <UserProfileProvider>
                <Outlet />
              </UserProfileProvider>
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <ApplicationLayout>
                <Home />
              </ApplicationLayout>
            }
          />
          <Route
            path='activity'
            element={
              <ApplicationLayout>
                <Activity />
              </ApplicationLayout>
            }
          />
          <Route
            path='opportunities'
            element={
              <ApplicationLayout>
                <Opportunities />
              </ApplicationLayout>
            }
          />
          <Route
            path='profile'
            element={
              <ApplicationLayout>
                <Profile />
              </ApplicationLayout>
            }
          />
          <Route
            path='opportunities-details'
            element={
              <ApplicationLayout>
                <OpportunitiesDetails />
              </ApplicationLayout>
            }
          />
          <Route
            path='enrollment'
            element={
              <PrivateRoute>
                <AssessmentProvider>
                  <UserProfileProvider>
                    <EnrollmentScreen />
                  </UserProfileProvider>
                </AssessmentProvider>
              </PrivateRoute>
            }
          />
        </Route>

        <Route path='*' element={<Text>Page not found</Text>} />
      </Route>
    </Routes>
  );
};

export default Pages;
