import { ResidentDashSectionStoryblok } from '@maivenenergy/storyblok';
import { ISbStoryData, StoryblokComponent } from '@storyblok/react';

import { useGetDashboardStories } from '@/api';
import DefaultHome from '@/assets/default-home.svg';
import MapPin from '@/assets/map-pin.svg';
import CompanyLogoFooter from '@/components/CompanyLogoFooter';
import Text from '@/components/Typography';
import { useUserProfileContext } from '@/contexts/UserProfileContext';
import { logger } from '@/lib/Logger';

const Home = () => {
  const { profile } = useUserProfileContext();
  const { data, isLoading, error } = useGetDashboardStories();

  if (isLoading) {
    return <></>;
  }

  const errorState = !data || error;

  if (errorState) {
    logger.error('Failed to fetch dashboard stories', { error });
    return <Text>Something wrong happened. Try again</Text>;
  }

  return (
    <div className='flex flex-col gap-[10px] bg-gray-50'>
      <div className='p-4 py-1'>
        <Text className='text-2xl font-bold'>{data.story.content.title}</Text>
        <Text>{data.story.content.description}</Text>
      </div>
      <div className='flex flex-col gap-4 p-4'>
        {data.userHomeData.propertyImage ? (
          <img src={data.userHomeData.propertyImage} alt='house' className='max-h-[180px]' />
        ) : (
          <div className='flex flex-col items-center rounded-lg bg-purple-500 p-4 py-10'>
            <img src={DefaultHome} alt='house' className='w-24' />
          </div>
        )}
        <div className='flex gap-2 pr-4'>
          <img src={MapPin} alt='map-pin' />
          <Text>{profile?.properties[0]?.address?.address1 ?? ''}</Text>
        </div>
      </div>
      {data.story.content.sections?.map((section) => {
        const _section = section as ISbStoryData<ResidentDashSectionStoryblok>;
        return <StoryblokComponent blok={_section.content} key={_section.uuid} />;
      })}
      <CompanyLogoFooter />
    </div>
  );
};

export default Home;
