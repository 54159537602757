import React from 'react';

import AssessmentFormCoreField from './AssessmentFormCoreField';

import { AssessmentResult, StoryblokAssessmentCoreDataField } from '@/api';

interface Props {
  coreFields: StoryblokAssessmentCoreDataField[];
  userId: string;
  affiliateId: string;
  propertyId: string;
  results: Record<string, AssessmentResult>;
}

const AssessmentFormCoreFields: React.FC<Props> = ({ coreFields, userId, affiliateId, propertyId, results }) => {
  return (
    <div id='assessment-form-core-fields' className='flex flex-col gap-3'>
      {coreFields.map((coreField) => (
        <AssessmentFormCoreField
          key={coreField.data_field_name}
          coreField={coreField.data_field_name}
          userId={userId}
          affiliateId={affiliateId}
          propertyId={propertyId}
          result={results[coreField.data_field_name]}
          results={results}
        />
      ))}
    </div>
  );
};

export default AssessmentFormCoreFields;
