import TopBar from '../../layouts/ApplicationLayout/TopBar';

interface EnrollmentLayoutProps {
  children: React.ReactNode;
}

const EnrollmentLayout = ({ children }: EnrollmentLayoutProps) => {
  return (
    <div className='mx-auto flex w-full flex-col pb-16 md:max-w-2xl'>
      <TopBar showBackButton />
      {children}
    </div>
  );
};

export default EnrollmentLayout;
