import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { ButtonProps } from '@/components/Button';
import Button from '@/components/Button';

interface ButtonConfig {
  text?: string;
  style?: ButtonProps['buttonStyle'];
  size?: ButtonProps['size'];
  type?: ButtonProps['buttonType'];
  disabled?: boolean;
  onClick?: () => Promise<void>;
}

interface Step {
  component: FC<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  backButton?: ButtonConfig;
  nextButton?: ButtonConfig;
  props?: Record<string, unknown>;
}

interface StepperContextType {
  currentStepIndex: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goToStep: (index: number) => void;
  stepsCount: number;
}

interface StepperProviderProps {
  children: ReactNode;
  steps: Step[];
}

interface StepperProps {
  steps: Step[];
}

const StepperContext = createContext<StepperContextType | undefined>(undefined);

const useStepper = (): StepperContextType => {
  const context = useContext(StepperContext);
  if (!context) {
    throw new Error('useStepper must be used within StepperProvider');
  }
  return context;
};

const StepperProvider: FC<StepperProviderProps> = ({ children, steps }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const goToNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const goToStep = (index: number) => {
    if (index >= 0 && index < steps.length) {
      setCurrentStepIndex(index);
    }
  };

  return (
    <StepperContext.Provider
      value={{
        currentStepIndex,
        goToNextStep,
        goToPreviousStep,
        goToStep,
        stepsCount: steps.length,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};

const Stepper: FC<StepperProps> = ({ steps }) => {
  const { currentStepIndex, goToNextStep, goToPreviousStep } = useStepper();
  const currentStep = steps[currentStepIndex];
  const StepComponent = currentStep?.component;

  if (!StepComponent) return null;

  return (
    <div className='flex flex-col gap-4 p-4'>
      <StepComponent {...currentStep.props} />
      <div className='mt-6 flex items-center justify-center space-x-4'>
        {currentStep.backButton && (
          <Button
            className={`${!currentStep.nextButton ? 'w-full' : ''}`}
            buttonStyle={currentStep.backButton.style || 'light'}
            buttonType={currentStep.backButton.type || 'normal'}
            size={currentStep.backButton.size || 'base'}
            onClick={async () => {
              try {
                await currentStep.backButton?.onClick?.();
                goToPreviousStep();
              } catch (error) {
                console.error(error);
              }
            }}
            disabled={currentStep.backButton.disabled || false}
          >
            {currentStep.backButton.text}
          </Button>
        )}
        {currentStep.nextButton && (
          <Button
            className={`${!currentStep.backButton ? 'w-full' : ''}`}
            buttonStyle={currentStep.nextButton.style || 'dark'}
            buttonType={currentStep.nextButton.type || 'normal'}
            size={currentStep.nextButton.size || 'base'}
            onClick={async () => {
              try {
                await currentStep.nextButton?.onClick?.();
                goToNextStep();
              } catch (error) {
                console.error(error);
              }
            }}
            disabled={currentStep.nextButton.disabled || false}
          >
            {currentStep.nextButton.text}
          </Button>
        )}
      </div>
    </div>
  );
};

export { Stepper, StepperProvider, useStepper };
export type { ButtonConfig, Step, StepperProps, StepperProviderProps };
