import { ShareIcon } from '@heroicons/react/16/solid';

import { useEnrollment } from '.';

import { useGetEnrollmentSuccessStory } from '@/api/hooks/queries/useGetEnrollmentSuccessStory';
import Button from '@/components/Button';
import Text from '@/components/Typography';
import { logger } from '@/lib/Logger';
import RichtextDisplay from '@/storyblokComponents/RichtextDisplay';

const EnrollmentSuccess = () => {
  const { programName } = useEnrollment();
  const { data, isLoading, error } = useGetEnrollmentSuccessStory();
  const blok = data?.content;
  const errorState = !data || !blok || error;

  if (isLoading) {
    return null;
  }

  if (errorState) {
    logger.error('Failed to fetch enrollment sign story', { error });
    return <Text>Something wrong happened. Try again</Text>;
  }

  return (
    <div className='flex flex-col gap-[10px] p-4 align-middle'>
      <Text className='text-center font-bold'>{programName}</Text>
      {blok.graphic && (
        <div className='mt-3 flex justify-center'>
          <img src={blok.graphic.filename || ''} alt='enrollment-disclaimer-image' className='w-52' />
        </div>
      )}
      <Text className='text-center text-2xl font-bold'>{blok.highlight}</Text>
      <Button buttonType='outline' buttonStyle='light' className='mt-4 min-w-44'>
        <div className='flex justify-center align-middle'>
          <ShareIcon className='mr-2 w-4' />
          <Text className='text-base'>{blok.share_button_text}</Text>
        </div>
      </Button>
      <div className='mt-6'>{blok.description && <RichtextDisplay richText={blok.description} />}</div>
    </div>
  );
};

export default EnrollmentSuccess;
