import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { Prerequisites } from '@/api/types/Prerequisites';

export const useGetUserPrereqs = (recommendationStrategy: string) => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: ['get-user-prerequisites'] as const,
    queryFn: async () => {
      const client = await getClient();

      const response = await client.get<Prerequisites>(
        `/stories/prerequisites?recommendationStrategy=${recommendationStrategy}`
      );

      return response.data;
    },
  });
};
