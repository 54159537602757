import { ResidentDashSectionStoryblok } from '@maivenenergy/storyblok';
import { StoryblokComponent } from '@storyblok/react';

interface ResidentDashSectionProps {
  blok: ResidentDashSectionStoryblok & { className?: string };
}

const MeasureSummary = ({ blok }: ResidentDashSectionProps) => {
  return (
    <div className='flex flex-col gap-4 bg-gray-100 px-6 pb-8 pt-4'>
      {blok?.related_items?.map((blok) => {
        return typeof blok === 'string' ? null : <StoryblokComponent blok={blok.content} key={blok.uuid} />;
      })}
    </div>
  );
};

export default MeasureSummary;
