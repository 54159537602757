import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { Paginated } from '@/api/types';

export interface UseGetAssessmentQuestionLookupDetailsParams {
  assessmentId: string;
  questionId: string;
  lookupTable?: string;
  lookupValue?: string;
  component?: string;
}

export const useGetAssessmentQuestionLookupDetails = (params: UseGetAssessmentQuestionLookupDetailsParams) => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: [
      'assessment-lookup-value',
      {
        lookupTable: params.lookupTable,
        lookupValue: params.lookupValue,
        component: params.component,
      },
    ] as const,
    queryFn: async ({ queryKey: [, { lookupTable, lookupValue, component }] }) => {
      const client = await getClient();

      const search = new URLSearchParams({});

      if (component) {
        search.set('component', component);
      }

      if (lookupTable) {
        search.set('lookupTable', lookupTable);
      }

      if (lookupValue) {
        search.set('lookupValue', lookupValue);
      }

      const response = await client.get<Paginated<string>>(
        `assessments/${params.assessmentId}/questions/${params.questionId}/lookup`,
        {
          params: search,
        }
      );

      return response.data;
    },
  });
};
