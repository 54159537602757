import { useMutation } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';

interface GetAssessmentQuestionUploadRequestParams {
  assessmentId: string;
  questionId: string;
  files: { fileName: string; contentType: string }[];
}

export const useGetAssessmentQuestionUpload = () => {
  const { getClient } = useApiClient();

  return useMutation({
    mutationFn: async (requestParams: GetAssessmentQuestionUploadRequestParams) => {
      const client = await getClient();

      return client
        .post<{
          total: number;
          data: {
            path: string;
            fileName: string;
            url: string;
            getUrl: string;
          }[];
        }>(`/assessments/${requestParams.assessmentId}/questions/${requestParams.questionId}/upload`, {
          files: requestParams.files,
        })
        .then((response) => response.data.data);
    },
  });
};
