import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { OpportunitiesStory, RecommendationStrategy } from '@/api/types';

export const useGetOpportunityDetailStory = (slug: string, recommendationStrategy: RecommendationStrategy) => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: ['opportunities-detail-story'] as const,
    queryFn: async () => {
      const client = await getClient();

      const response = await client.get<OpportunitiesStory>(
        `/stories/opportunity-details/${slug}?recommendationStrategy=${recommendationStrategy}`
      );

      return response.data;
    },
  });
};
