import { AssessmentQuestionInfoStoryblok } from '@maivenenergy/storyblok';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AssessmentFormSectionContent } from './types';
import { SHARE_USAGE_DATA_SUBSECTION, UTILITIES_INFORMATION_SECTION } from '../constants';

import {
  AssessmentResult,
  // ProfileType,
  StoryblokAssessmentQuestion,
  StoryblokAssessmentCoreDataField,
  StoryblokAssessmentSubSection,
  AssessmentSubSectionType,
} from '@/api';

export type AssessmentFormSubSectionQuestion = StoryblokAssessmentQuestion & {
  dependingQuestions: string[];
  filterOnQuestion?: StoryblokAssessmentQuestion;
};

export type AssessmentFormSubSectionQuestionGroup = StoryblokAssessmentQuestion & {
  questions: AssessmentFormSubSectionQuestion[];
};

export interface AssessmentFormSubSectionContent {
  header: string;
  order: number;
  description: string;
  shouldPrevSection: boolean;
  nextButtonLabel?: string;
  resultIndex?: number;
  overrideNextButtonAction?: () => void;
  questions: AssessmentFormSubSectionQuestion[];
  coreDataFields: StoryblokAssessmentCoreDataField[];
  sectionType?: string;
  newNextSubsection?: string;
  newPrevSubsection?: string;
  info?: AssessmentQuestionInfoStoryblok[];
}

export type AssessmentFormSubSectionContentByName = Record<string, AssessmentFormSubSectionContent>;

const resolveQuestionDependencies = (
  subsections: StoryblokAssessmentSubSection[],
  questions: StoryblokAssessmentQuestion[],
  results: Record<string, AssessmentResult>
): AssessmentFormSubSectionQuestion[] => {
  return questions.reduce<AssessmentFormSubSectionQuestion[]>((a, c) => {
    // TODO (Aimee): expand core field / question functionality to support dependencies
    if (
      (c.name === 'landlord_email' ||
        c.name === 'landlord_phone' ||
        c.name === 'landlord_language' ||
        c.name === 'landlord_address' ||
        c.name === 'landlord_group_name') &&
      (results?.['own/rent'].value === 'own' || results?.['own/rent'].value === 'landlord')
    ) {
      return a;
    }
    const dependsOnValues = Array.isArray(c.depends_on_value)
      ? c.depends_on_value
      : c.depends_on_value
        ? [c.depends_on_value]
        : [];

    if (c.depends_on && dependsOnValues.some(Boolean)) {
      const dependsOnQuestion = questions.find((q) => q.question_id === c.depends_on?.[0]);

      if (dependsOnQuestion) {
        const result = results[dependsOnQuestion.question_id];
        const hasValue =
          dependsOnValues.includes(result?.value) ||
          result?.values.some((r) => dependsOnValues.includes(r.value)) ||
          (dependsOnValues.includes('user_provided') && result?.value);

        if (!hasValue) {
          return a;
        }
      }
    }

    const dependingQuestions: string[] = [];

    dependingQuestions.push(
      ...subsections.reduce((acc, s) => {
        if (s.depends_on === c.question_id) {
          const questionIds = s.questions?.map((q) => q.question_id) ?? [];

          acc.push(...questionIds);
        }

        return acc;
      }, [] as string[])
    );

    questions.forEach((q) => {
      if (q?.depends_on?.[0] === c.question_id) {
        dependingQuestions.push(q.question_id);
      }
    });

    const filterOnQuestion = questions.find((q) => q.question_id === c?.filter_on?.[0]);

    a.push({
      ...c,
      dependingQuestions, // TODO: should be empty array if none
      filterOnQuestion,
    });

    return a;
  }, []);
};

const getNextButtonLabel = ({
  sectionName,
  sectionNextButtonLabel,
  subsectionName,
  skipManualUsageData,
}: {
  sectionName: string;
  sectionNextButtonLabel: string;
  subsectionName: string;
  skipManualUsageData: boolean;
}): string => {
  if (
    sectionName === UTILITIES_INFORMATION_SECTION &&
    subsectionName === SHARE_USAGE_DATA_SUBSECTION &&
    skipManualUsageData
  ) {
    return 'Next';
  }
  if (sectionName === UTILITIES_INFORMATION_SECTION && subsectionName === SHARE_USAGE_DATA_SUBSECTION) {
    return 'Skip';
  }
  return sectionNextButtonLabel;
};

export const useAssessmentFormSubSections = (
  section: AssessmentFormSectionContent,
  results: Record<string, AssessmentResult>
  // userProfile: ProfileType
) => {
  const { subsectionNames, subsections } = useMemo(
    () =>
      section.subsections.reduce(
        (a, c, idx, arr) => {
          const getKey = (position: number, header: string): string => position + header;

          const nextSubSection = idx < arr.length - 1 ? getKey(idx + 1, arr[idx + 1].header) : undefined;
          let prevSubSection = idx > 0 ? getKey(idx - 1, arr[idx - 1].header) : '0cover';

          const questions = resolveQuestionDependencies(section.subsections, c.questions ?? [], results);
          const key = getKey(idx, c.header);
          const prevSubSectionName = a.subsectionNames[a.subsectionNames.length - 1];
          const prev = a.subsections[prevSubSectionName];
          const newPrevSubsection = prevSubSectionName;

          if (prev) {
            prev.newNextSubsection = key;
          }

          if (c.repeats_on?.length) {
            const [repeatsOn] = c.repeats_on;
            const repeatsOnResult = results[repeatsOn];
            const repeatValue = +repeatsOnResult?.value;

            if (!isNaN(repeatValue)) {
              for (let i = 0; i < repeatValue; i++) {
                const repeatedKey = `${key}${i}`;
                const repeatedNextSubSection = i + 1 < repeatValue ? `${key}${i + 1}` : nextSubSection;
                const repeatedPrevSubSection = i > 0 ? `${key}${i - 1}` : prevSubSection;

                if (i === 0 && prev) {
                  prev.newNextSubsection = repeatedKey;
                }

                a.subsectionNames.push(repeatedKey);

                a.subsections[repeatedKey] = {
                  description: c.description?.replace('{index}', (i + 1).toString()) ?? '',
                  header: c.header,
                  order: idx + 1,
                  questions,
                  coreDataFields: c.core_data_fields ?? [],
                  sectionType: c.section_type,
                  info: c.info,
                  shouldPrevSection: !repeatedPrevSubSection,
                  newNextSubsection: repeatedNextSubSection,
                  newPrevSubsection: repeatedPrevSubSection,
                  nextButtonLabel: getNextButtonLabel({
                    sectionNextButtonLabel: section.nextButtonLabel ?? 'Next',
                    sectionName: section.title,
                    subsectionName: repeatedKey,
                    // TODO (Aimee): Comment this back in when MI no longer needs both synced and manual usage inputs
                    // skipManualUsageData: userProfile?.shared_usage_data,
                    skipManualUsageData: false,
                  }),
                  overrideNextButtonAction: section.overrideNextButtonAction,
                  resultIndex: i,
                };
              }
            }
          } else {
            if (idx > 0) {
              const prevItem = arr[idx - 1];
              const prevKey = getKey(idx - 1, prevItem.header);

              if (prevItem?.repeats_on) {
                const prevRepeatsOnResult = results[prevItem.repeats_on];
                const prevRepeatValue = +prevRepeatsOnResult?.value;

                if (!isNaN(prevRepeatValue)) {
                  const repeatedPrevSubSection = `${prevKey}${prevRepeatValue - 1}`;

                  prevSubSection = getKey(idx - 2, arr[idx - 2].header);
                  if (prevRepeatValue > 0) {
                    prevSubSection = repeatedPrevSubSection;
                  }
                }
              }
            }

            a.subsectionNames.push(key);
            a.subsections[key] = {
              description: c.description ?? '',
              header: c.header,
              order: idx,
              questions,
              coreDataFields: c.core_data_fields ?? [],
              sectionType: c.section_type,
              info: c.info,
              newPrevSubsection,
              shouldPrevSection: !prevSubSection,
              nextButtonLabel: getNextButtonLabel({
                sectionNextButtonLabel: section.nextButtonLabel ?? 'Next',
                sectionName: section.title,
                subsectionName: key,
                // TODO (Aimee): Comment this back in when MI no longer needs both synced and manual usage inputs
                // skipManualUsageData: userProfile?.shared_usage_data,
                skipManualUsageData: false,
              }),
              overrideNextButtonAction: section.overrideNextButtonAction,
            };
          }

          return a;
        },
        {
          subsectionNames: ['0cover'] as string[],
          subsections: {
            '0cover': {
              description: section.cover.body,
              header: 'cover',
              questions: [],
              order: 0,
              shouldPrevSection: false,
              nextButtonLabel: section.nextButtonLabel,
              overrideNextButtonAction: section.overrideNextButtonAction,
              nextSubSection: `${0}${section.subsections[0]?.header}`,
              coreDataFields: [],
            },
          } as AssessmentFormSubSectionContentByName,
        }
      ),
    [section, results]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentSubsection, setCurrentSubsection] = useState<string | undefined>(subsectionNames[0]);

  useEffect(() => {
    const currentSubsectionSearchParam = searchParams.get('currentSubsection');

    if (currentSubsectionSearchParam) {
      setCurrentSubsection(currentSubsectionSearchParam);
      setSearchParams((current) => {
        current.delete('currentSubsection');

        return current;
      });
    }
  }, [searchParams, setCurrentSubsection, setSearchParams]);

  const canProceedToNextStep = useMemo(() => {
    if (!currentSubsection || !subsections[currentSubsection]) return true;

    const currentSubsectionContent = subsections[currentSubsection];
    // Skip validation for cover and special sections
    if (
      currentSubsectionContent.header === 'cover' ||
      currentSubsectionContent.sectionType === AssessmentSubSectionType.SHARE_USAGE_DATA
    ) {
      return true;
    }

    // Only validate if address field is present
    if (currentSubsectionContent.coreDataFields?.some((field) => field.data_field_name === 'address')) {
      const hasAddress = !![results.address?.value, results.city?.value, results.state?.value, results.zip?.value]
        .filter((value) => value?.trim?.())
        .join(' ');

      const isAddressValid = results.address_validation?.value === 'true';

      if (!hasAddress || !isAddressValid) return false;
    }

    const structureQuestion = currentSubsectionContent.questions.find((question) => question.name === 'structure');

    // Check if large multi family is selected for the structure question
    if (structureQuestion && results?.[structureQuestion.question_id]?.value === 'large_multi_family') {
      return false;
    }

    // Check if all required questions have answers
    return currentSubsectionContent.questions.every((question) => {
      if (!question.required) return true;

      const result = results[question.question_id];
      return result && (!!result.value || result.values.length > 0);
    });
  }, [currentSubsection, subsections, results]);

  return {
    subsectionNames,
    subsections,
    currentSubsection,
    canProceedToNextStep,
    setCurrentSubsection: (str: string) => setCurrentSubsection(str),
    onPreviousSubsection: () => {
      if (!currentSubsection) return;

      const subsection = subsections[currentSubsection];

      if (subsection.newPrevSubsection) {
        setSearchParams({
          currentSubsection: subsection.newPrevSubsection,
        });
      }
    },
    onNextSubsection: () => {
      if (!currentSubsection) return;

      const subsection = subsections[currentSubsection];

      if (subsection.newNextSubsection) {
        setSearchParams({
          currentSubsection: subsection.newNextSubsection,
        });
      } else if (section.nextSection) {
        setSearchParams({
          currentSection: section.nextSection,
        });
      }
    },
  };
};
