import { CurrencyDollarIcon, TagIcon } from '@heroicons/react/24/solid';
import React from 'react';

import Text from '@/components/Typography';

export interface TagProps {
  className?: string;
  label: string;
  icon?: 'default' | 'tag' | 'none';
  featured?: boolean;
}

const Tag: React.FC<TagProps> = ({ className, label, icon = 'default', featured = false }) => {
  return (
    <span
      className={`inline-flex items-center gap-1 rounded-full ${featured ? 'bg-lime-500' : 'bg-gray-300'} px-2 py-0.5 text-gray-900 ${className ?? ''}`}
    >
      {icon === 'default' && <CurrencyDollarIcon className='size-3' />}
      {icon === 'tag' && <TagIcon className='size-3' />}
      <Text as='span' size='xs' className='font-medium'>
        {label}
      </Text>
    </span>
  );
};

export default Tag;
