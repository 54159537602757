import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { RichtextStoryblok } from '@maivenenergy/storyblok';
import { useState, ElementType } from 'react';

import ModalDisplay from '@/components/ModalDisplay';
import Text from '@/components/Typography';
import RichtextDisplay from '@/storyblokComponents/RichtextDisplay';

interface ImageTextModalDisplayProps {
  triggerImage?: string;
  modalImage?: string;
  triggerText: string;
  title: string;
  subtitle: string;
  richText: RichtextStoryblok;
  className?: string;
  HeroIcon?: ElementType;
}

const ImageTextModalDisplay: React.FC<ImageTextModalDisplayProps> = ({
  triggerImage,
  modalImage,
  triggerText,
  className,
  richText,
  title,
  subtitle,
  HeroIcon,
}) => {
  const [showModal, setShowModal] = useState(false);

  const closeModalHandle = () => {
    setShowModal(false);
  };

  return (
    <div
      className={`flex cursor-pointer items-center justify-start rounded-xl border border-gray-200 bg-white p-2 hover:bg-gray-100 ${
        className ?? ''
      }`}
      onClick={() => {
        setShowModal(true);
      }}
    >
      {triggerImage && <img src={triggerImage} alt='model-display-image' className='w-14 rounded' />}
      {HeroIcon && <HeroIcon className='w-4' />}
      <Text className='p-2 text-sm'>{triggerText}</Text>
      <div className='ml-auto p-2'>
        <ChevronRightIcon className='size-4' />
      </div>
      <ModalDisplay showModal={showModal} closeModalHandle={closeModalHandle}>
        <div className='flex flex-col px-4 py-2'>
          {modalImage && <img src={modalImage} alt='info-image' className='max-h-[180px] rounded-2xl' />}
          <Text className='px-4 py-1 pt-6 text-2xl font-bold'>{title}</Text>
          <div className='flex flex-col gap-4 px-4 py-2'>
            <Text className='font-bold'>{subtitle}</Text>
            <RichtextDisplay richText={richText} className='text-sm' />
          </div>
        </div>
      </ModalDisplay>
    </div>
  );
};

export default ImageTextModalDisplay;
