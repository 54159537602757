import { FC, ReactNode, createContext, useContext, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import EnrollmentDisclaimer from './EnrollmentDisclaimer';
import EnrollmentPrerequisites from './EnrollmentPrerequisites';
import EnrollmentReview from './EnrollmentReview';
import EnrollmentSign from './EnrollmentSign';
import EnrollmentSuccess from './EnrollmentSuccess';
import ScopeOfWork from './ScopeOfWork';

import { RecommendationStrategy, useAcceptPPA, useGetEnrollmentStepsStory, useGetAssessmentPPA } from '@/api';
import { Step, Stepper, StepperProvider } from '@/components/Stepper';
import { ButtonConfig, Step as StepType } from '@/components/Stepper';
import { useAssessmentContext } from '@/contexts/AssessmentContext';
import EnrollmentLayout from '@/layouts/EnrollmentLayout';

interface EnrollmentContextType {
  recommendationStrategy: RecommendationStrategy;
  programName: string;
}

interface EnrollmentProviderProps {
  children: ReactNode;
  recommendationStrategy: RecommendationStrategy;
  programName: string;
}

const EnrollmentContext = createContext<EnrollmentContextType | undefined>(undefined);

export const useEnrollment = (): EnrollmentContextType => {
  const context = useContext(EnrollmentContext);
  if (!context) {
    throw new Error('useEnrollment must be used within EnrollmentProvider');
  }
  return context;
};

const EnrollmentProvider: FC<EnrollmentProviderProps> = ({ children, recommendationStrategy, programName }) => {
  return (
    <EnrollmentContext.Provider
      value={{
        recommendationStrategy,
        programName,
      }}
    >
      {children}
    </EnrollmentContext.Provider>
  );
};

const EnrollmentScreen: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { recommendationStrategy } = location.state ?? {};

  const { details } = useAssessmentContext();
  const { mutateAsync: acceptPPA, isPending: isAcceptingPPA } = useAcceptPPA();
  const { data: ppaData } = useGetAssessmentPPA(details.assessmentId);
  const { data } = useGetEnrollmentStepsStory();
  const [steps, setSteps] = useState<Step[]>([]);

  const acceptAgreement = useCallback(async () => {
    await acceptPPA({ assessmentId: details.assessmentId, recommendationStrategy });
  }, [acceptPPA, details.assessmentId, recommendationStrategy]);

  useEffect(() => {
    const componentMap: {
      [key: string]: Step;
    } = {
      EnrollmentScopeOfWork: {
        component: ScopeOfWork,
        backButton: {
          onClick: async () => navigate('/dashboard/opportunities'),
        },
      },
      EnrollmentPrerequisites: {
        component: EnrollmentPrerequisites,
      },
      EnrollmentDisclaimer: {
        component: EnrollmentDisclaimer,
      },
      EnrollmentReview: {
        component: EnrollmentReview,
      },
      EnrollmentSignAgreement: {
        component: EnrollmentSign,
        props: {
          isLoading: isAcceptingPPA,
        },
        nextButton: {
          onClick: acceptAgreement,
          disabled: isAcceptingPPA,
        },
      },
      EnrollmentSuccess: {
        component: EnrollmentSuccess,
        nextButton: {
          onClick: async () => navigate('/dashboard'),
        },
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const storyblokSteps = (data?.story?.content?.steps as any[]) ?? [];

    // if the user has already accepted the PPA, skip the other steps
    if (ppaData) {
      // find the EnrollmentSuccess step configuration from Storyblok
      const successStepStory = storyblokSteps.find((step) => step?.content?.component === 'EnrollmentSuccess');
      const nextButtonStory = successStepStory?.content?.next_button?.[0];

      const successStep: StepType = {
        component: EnrollmentSuccess,
        nextButton: {
          text: nextButtonStory?.text ?? 'Return to Dashboard',
          style: 'dark',
          type: 'normal',
          onClick: async () => navigate('/dashboard'),
        },
      };
      setSteps([successStep]);
      return;
    }

    const preparedSteps = storyblokSteps?.reduce((acc, cur) => {
      const component = cur?.content?.component;
      const { component: Component, props, nextButton = {}, backButton } = componentMap[component];

      if (!component || !Component) {
        return acc;
      }

      const nextButtonStory = cur?.content?.next_button?.[0];
      const backButtonStory = cur?.content?.back_button?.[0];

      const step: StepType = {
        component: Component,
        props,
        nextButton: {
          ...nextButton,
          text: nextButtonStory?.text ?? '',
          style: 'dark',
          type: 'normal',
        },
      };

      if (backButton) {
        step.backButton = {
          ...backButton,
          text: backButtonStory?.text ?? '',
          style: 'light',
          type: 'outline',
        };
      }
      acc.push(step);

      return acc;
    }, []);

    setSteps(preparedSteps);
  }, [acceptAgreement, data?.story?.content?.steps, isAcceptingPPA, navigate, setSteps]);

  return (
    <EnrollmentProvider
      programName={data?.story?.content?.program_name ?? ''}
      recommendationStrategy={recommendationStrategy}
    >
      <StepperProvider steps={steps}>
        <EnrollmentLayout>
          <Stepper steps={steps} />
        </EnrollmentLayout>
      </StepperProvider>
    </EnrollmentProvider>
  );
};

export default EnrollmentScreen;
