import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

import { useEnrollment } from '.';

import { useGetUserPrereqs } from '@/api';
import PrereqsImage from '@/assets/prereqs.svg';
import ImageTextModalDisplay from '@/components/ImageTextModalDisplay';
import { useStepper } from '@/components/Stepper';
import Text from '@/components/Typography';
import { logger } from '@/lib/Logger';

const EnrollmentPrerequisites = () => {
  const { programName, recommendationStrategy } = useEnrollment();
  const { data, isLoading, error } = useGetUserPrereqs(recommendationStrategy);
  const { goToNextStep } = useStepper();

  if (isLoading) {
    return null;
  }

  if (!data?.prereqs?.length) {
    goToNextStep();
  }

  if (error) {
    logger.error('Failed to fetch user prerequisites', { error });
    return <Text>Something wrong happened. Try again</Text>;
  }

  const totalCost = data?.prereqs?.reduce((acc, cur) => {
    const parsedCost = parseFloat(cur.cost_usd);
    return acc + parsedCost;
  }, 0);

  const formattedTotal = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(totalCost ?? 0);

  return (
    <div className='flex flex-col gap-[10px] rounded-lg bg-white p-8'>
      <Text className='p-4 pt-2 text-lg font-bold'>{programName}</Text>
      <div className='flex justify-center px-4'>
        <img src={PrereqsImage} alt='prereqs-image' className='w-52' />
      </div>
      <Text className='p-4 pt-6 text-2xl font-bold'>
        This program may require specific measures to be completed before installs or upgrades can take place.
      </Text>
      <div className='flex flex-col gap-4 p-4'>
        <Text className='font-bold'>Prerequisite Measures</Text>
        <Text className='text-sm'>
          Your contractor will inform you of any necessary steps and associated costs, giving you the option to decide
          whether to proceed.
        </Text>
      </div>
      <div className='flex flex-col'>
        <div className='flex flex-col gap-3 p-3'>
          <div className='flex'>
            <Text className='text-xs font-bold leading-none'>Potential Pre-Req Costs</Text>
            <Text className='ml-auto text-xs font-bold leading-none'>{formattedTotal}</Text>
          </div>
          <Text className='text-xs'>One-Time costs to you</Text>
        </div>
        <div className='flex flex-col gap-[10px] p-2'>
          {data?.prereqs?.map((prereq) => {
            return (
              <div key={prereq.content.title}>
                <ImageTextModalDisplay
                  modalImage={prereq.content.graphic?.filename || ''}
                  richText={prereq.content.description || { type: 'doc', content: [] }}
                  title={prereq.content.title || ''}
                  triggerText={prereq.content.title || ''}
                  subtitle=''
                  HeroIcon={() => <ExclamationTriangleIcon className='w-4 text-orange-500' />}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EnrollmentPrerequisites;
