import { PhoneIcon } from '@heroicons/react/16/solid';
import { GlobeAltIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { UtilityDetailsBlockStoryblok } from '@maivenenergy/storyblok';
import { useState } from 'react';

import RichtextDisplay from '../RichtextDisplay';

import ModalDisplay from '@/components/ModalDisplay';
import Text from '@/components/Typography';

interface UtilityDetailsBlockProps {
  blok: UtilityDetailsBlockStoryblok;
}

const UtilityDetailsBlock = ({ blok }: UtilityDetailsBlockProps) => {
  const [showModal, setShowModal] = useState(false);

  const closeModalHandle = () => {
    setShowModal(false);
  };
  return (
    <div className='flex rounded-lg bg-gray-100 p-2' onClick={() => setShowModal(true)}>
      <div className='flex justify-center'>
        <div className='mr-3'>
          {blok?.utility_logo?.filename && (
            <img src={blok.utility_logo.filename} alt='pg&e-logo' className='mr-px w-12' />
          )}
        </div>
      </div>
      <div className='flex flex-1 flex-col justify-center'>
        {blok?.preview_title && <Text className='text-xs font-bold text-gray-500'>{blok.preview_title}</Text>}
        {blok?.utility_name && <Text className='text-sm font-bold text-black'>{blok.utility_name}</Text>}
      </div>
      <ModalDisplay showModal={showModal} closeModalHandle={closeModalHandle}>
        <div className='flex flex-col px-4 py-2'>
          {blok?.utility_logo?.filename && (
            <img src={blok.utility_logo.filename} alt='pg&e-logo' className='mr-px w-12' />
          )}
          {blok?.utility_description && <RichtextDisplay richText={blok.utility_description} className='mt-4' />}
          <hr className='mt-4 border-black' />
          {blok?.program_contact_title && (
            <Text className='mt-4 text-base font-bold'>{blok.program_contact_title}</Text>
          )}
          {blok?.program_contact_name && <Text className='mt-2 text-base'>{blok.program_contact_name}</Text>}
          {blok?.program_contact_address && <Text className='text-base'>{blok.program_contact_address}</Text>}
          {blok?.program_contact_phone && blok?.program_contact_phone_display && (
            <a className='flex' href={`tel:${blok.program_contact_phone}`}>
              <div className='mt-4 flex flex-1 gap-2 align-middle'>
                <PhoneIcon className='size-6' />
                <span className='flex-1 text-sm font-bold'>{blok.program_contact_phone_display}</span>
              </div>
            </a>
          )}
          {blok?.program_contact_website && (
            <a href={`https://${blok.program_contact_website}`} target='_blank' rel='noreferrer'>
              <div className='mt-4 flex flex-1 gap-2 align-middle'>
                <GlobeAltIcon className='size-6' />
                <span className='flex-1 text-sm font-bold'>{blok.program_contact_website}</span>
              </div>
            </a>
          )}
          {blok?.program_contact_email && (
            <a href={`mailto:${blok.program_contact_email}`}>
              <div className='mt-4 flex flex-1 gap-2 align-middle'>
                <EnvelopeIcon className='size-6' />
                <span className='flex-1 text-sm font-bold'>{blok.program_contact_email}</span>
              </div>
            </a>
          )}
        </div>
      </ModalDisplay>
    </div>
  );
};

export default UtilityDetailsBlock;
