import {
  BoltIcon,
  ClockIcon,
  DevicePhoneMobileIcon,
  DocumentIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/outline';
import React, { createRef, useState } from 'react';

import AssessmentCover from '@/assets/assessment-cover.jpeg';
import MaivenLogo from '@/assets/maiven.svg';
import Wave from '@/assets/wave.jpeg';
import Button from '@/components/Button';
import Carousel from '@/components/Carousel';
import AssessmentLeaveModal from '@/pages/Assessment/components/AssessmentLeaveModal';
import AssessmentSliderItem from '@/pages/Assessment/components/AssessmentSliderItem';

interface Props {
  onBegin: () => void;
}

const slidersCount = 4;

const AssessmentIntroduction: React.FC<Props> = ({ onBegin }) => {
  const [canContinue, setCanContinue] = useState(false);
  const prevButtonRef = createRef<HTMLButtonElement>();
  const nextButtonRef = createRef<HTMLButtonElement>();

  return (
    <div style={{ backgroundImage: `url('${AssessmentCover}')` }} className={`relative min-h-dvh bg-cover bg-center`}>
      <div className='absolute flex size-full justify-center bg-black bg-opacity-50 text-white'>
        <div className='flex h-full flex-col justify-between gap-3 py-6 text-center md:w-1/2 md:justify-center md:p-0'>
          <Carousel
            nextButtonRef={nextButtonRef}
            prevButtonRef={prevButtonRef}
            containerClassName='flex-grow md:flex-grow-0 flex flex-col justify-center'
            reportSeenCount={(count) => {
              if (count === slidersCount) {
                setCanContinue(true);
              }
            }}
          >
            <AssessmentSliderItem
              icon={
                <div
                  className='relative mx-auto size-24 rounded-xl bg-cover bg-center p-4'
                  style={{ backgroundImage: `url('${Wave}')` }}
                >
                  <img src={MaivenLogo} alt='Maiven Logo' />
                </div>
              }
              title='Welcome to Your Home Energy Survey'
              subtitle='Powered by Maiven'
              description='Snap a few photos—your electric panel, siding, water heater, and more. Answer a few quick questions. Then let Maiven’s energy-nerd robot do the rest!'
            />
            <AssessmentSliderItem
              icon={<ClockIcon className='mx-auto size-24 text-yellow-500' />}
              title='The survey takes about 30 minutes'
              description='Go at your own pace—save progress, add photos later. The best part? Do it anytime, in anything. Pajamas? Cozy. Dinosaur costume? Iconic.'
            />
            <AssessmentSliderItem
              icon={
                <div className='relative mx-auto size-24'>
                  <DocumentIcon className='text-yellow-500' />
                  <BoltIcon className='absolute left-1/2 top-[62%] size-9 -translate-x-1/2 -translate-y-1/2 text-yellow-500' />
                </div>
              }
              title='Have your utility bills or online account login ready.'
              description='Efficiency is our thing. For the best Maiven results, sync your account or enter details manually—no judgment. Just have your info ready!'
            />
            <AssessmentSliderItem
              icon={
                <div className='relative mx-auto size-24'>
                  <DevicePhoneMobileIcon className='text-yellow-500' />
                  <ViewfinderCircleIcon className='absolute left-1/2 top-1/2 size-9 -translate-x-1/2 -translate-y-1/2 text-yellow-500' />
                </div>
              }
              title='Grab your Camera–you’re going to take a bunch of photos.'
              description='On your phone? Snap photos on the spot or upload from your library. On a computer? Just save and upload when prompted. Your appliances are about to get their close-up!.'
            />
          </Carousel>
          <div className='flex flex-col gap-2 px-10'>
            <Button
              buttonStyle='light'
              onClick={() => {
                if (canContinue) {
                  onBegin();
                }

                nextButtonRef.current?.click();
              }}
            >
              {canContinue ? 'Begin' : 'Next'}
            </Button>
            {!canContinue ? (
              <Button
                buttonType='link'
                inverted
                onClick={() => {
                  prevButtonRef.current?.click();
                }}
              >
                Back
              </Button>
            ) : (
              <AssessmentLeaveModal
                triggerButtonProps={{
                  inverted: true,
                  buttonType: 'link',
                }}
              >
                Sign out
              </AssessmentLeaveModal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentIntroduction;
