import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { ResidentDashSectionStoryblok } from '@maivenenergy/storyblok';
import { useLocation } from 'react-router-dom';

import { useGetUserPrereqs } from '@/api';
import ImageTextModalDisplay from '@/components/ImageTextModalDisplay';
import Text from '@/components/Typography';
import { logger } from '@/lib/Logger';

const PrerequisiteSummary = ({ blok }: { blok: ResidentDashSectionStoryblok }) => {
  const location = useLocation();
  const { data, isLoading, error } = useGetUserPrereqs(location.state?.recommendationStrategy);

  if (isLoading) {
    return null;
  }

  if (!data?.prereqs?.length) {
    return null;
  }

  if (error) {
    logger.error('Failed to fetch user prerequisites', { error });
    return <Text>Something wrong happened. Try again</Text>;
  }

  const totalCost = data?.prereqs?.reduce((acc, cur) => {
    const parsedCost = parseFloat(cur.cost_usd);
    return acc + parsedCost;
  }, 0);

  const formattedTotal = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(totalCost ?? 0);

  return (
    <div className='mb-4 flex flex-col gap-[10px] rounded-lg bg-white px-6 py-8'>
      {blok?.graphic?.filename && (
        <div className='h-36'>
          <img src={blok.graphic.filename} alt='program-summary' className='size-full rounded-lg object-cover' />
        </div>
      )}

      {blok?.title && <Text className='text-lg font-bold'>{blok.title}</Text>}
      {blok?.description && <Text>{blok.description}</Text>}
      <div className='flex flex-col'>
        <div className='mt-2 flex flex-col gap-3'>
          <div className='flex'>
            <Text className='text-xs font-bold leading-none'>Potential Pre-Req Costs</Text>
            <Text className='ml-auto text-xs font-bold leading-none'>{formattedTotal}</Text>
          </div>
          <Text className='text-xs'>One-Time costs to you</Text>
        </div>
        <div className='mt-4 flex flex-col gap-[10px]'>
          {data?.prereqs?.map((prereq) => {
            return (
              <div key={prereq.content?.title}>
                <ImageTextModalDisplay
                  modalImage={prereq.content?.graphic?.filename || ''}
                  richText={prereq.content?.description || { type: 'doc', content: [] }}
                  title={prereq.content?.title || ''}
                  triggerText={prereq.content?.title || ''}
                  subtitle=''
                  HeroIcon={() => <ExclamationTriangleIcon className='w-4 text-orange-500' />}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PrerequisiteSummary;
