import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { createRef, forwardRef, Fragment, useEffect, useState, Dispatch, SetStateAction } from 'react';

import FormFieldLabel from '@/components/FormFieldLabel';
import Text from '@/components/Typography';

export interface SelectItem<TValue extends string | number = string> {
  label: string;
  value: TValue;
  initialElement?: JSX.Element;
}

interface SelectProps<TValue extends string | number = string> {
  ['data-id']: string;
  items: SelectItem<TValue>[];
  label?: string;
  errors?: Record<string, unknown>;
  placeholder?: string;
  hasinfo?: boolean;
  onClickInfo?: Dispatch<SetStateAction<boolean>>;

  // Form Controls
  value: TValue;
  disabled?: boolean;
  name: string;
  onBlur?: () => void;
  onChange: (value: TValue) => void;
}

const placeholderSentry = 'PLACEHOLDER_SENTRY';

const Btn = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    btnRef: React.Ref<HTMLButtonElement>;
  }
>(({ btnRef, ...props }, _ref) => {
  return <button ref={btnRef} {...props} />;
});

Btn.displayName = 'Btn';

const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      label,
      items,
      onChange,
      value,
      'data-id': dataId,
      errors,
      onBlur,
      placeholder,
      disabled,
      hasinfo,
      onClickInfo,
      ...props
    },

    _ref
  ) => {
    const btnRef = createRef<HTMLButtonElement>();
    const containerRef = createRef<HTMLDivElement>();
    const [shouldOpenUp, setShouldOpenUp] = useState(false);

    const [selectedItem, setSelectedItem] = useState(
      (value ? items.find((item) => item.value === value) : undefined) ??
        (placeholder ? { label: placeholder, value: placeholderSentry } : items[0])
    );

    // This effect is used to determine if the dropdown should open upwards or downwards
    useEffect(() => {
      const btn = btnRef.current;
      const container = containerRef.current;
      let observer: MutationObserver;

      if (container && btn) {
        const { top: btnTop, height: btnHeight } = btn.getBoundingClientRect();

        observer = new MutationObserver((mutationList) => {
          mutationList.forEach((m) => {
            if (m.type !== 'childList') return;

            m.addedNodes.forEach((a) => {
              const el = a as HTMLElement;

              if (el.id === `${dataId}-options`) {
                const contentHeight = el.clientHeight;
                const newTop = btnTop + btnHeight;

                setShouldOpenUp(newTop + contentHeight > window.innerHeight);
              }
            });
          });
        });

        observer.observe(container, { childList: true, subtree: true });
      }

      return () => {
        observer?.disconnect();
      };
    }, [dataId, btnRef, containerRef]);

    useEffect(() => {
      const newItem = items.find((item) => item.value === value);

      if (newItem) {
        setSelectedItem(newItem);
      } else {
        setSelectedItem(placeholder ? { label: placeholder, value: placeholderSentry } : items[0]);
      }

      // Disabling the eslint rule since we only want to run this effect once, when the component mounts.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    return (
      <Listbox
        value={selectedItem}
        onChange={(item) => {
          setSelectedItem(item);
          onChange?.(item.value);
          onBlur?.();
        }}
      >
        <FormFieldLabel data-id={dataId} label={label} hasInfo={hasinfo} onClickInfo={onClickInfo} />
        <div id={`${dataId}-container`} ref={containerRef} className={clsx('relative', label && 'mt-1')}>
          <ListboxButton
            as={Btn}
            btnRef={btnRef}
            id={`${dataId}-btn`}
            disabled={disabled}
            className={clsx(
              'base-form-field relative cursor-pointer',
              'base-form-field-outline',
              'base-form-field-disabled',
              'focus-btn focus-visible:outline-purple-500'
            )}
          >
            <span className='flex items-center gap-3'>
              {selectedItem.initialElement}
              <span className='grow truncate'>{selectedItem.label}</span>
              <ChevronUpDownIcon aria-hidden='true' className='size-5 text-gray-400' />
            </span>
          </ListboxButton>

          <ListboxOptions
            transition
            id={`${dataId}-options`}
            className={clsx(
              'flex flex-col gap-2',
              'base-form-field absolute z-10 max-h-96 shadow-md',
              'base-form-field-outline overflow-auto bg-white',
              'data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in',
              shouldOpenUp ? 'bottom-full mb-1' : 'mt-1'
            )}
          >
            {items.map((item) => (
              <ListboxOption
                key={item.value}
                value={item}
                as={Fragment}
                //   className='group relative cursor-default select-none rounded-lg  text-gray-900 data-[focus]:bg-gray-600 data-[focus]:text-white [.group:not([data-selected])_&]:bg-yellow-100'
              >
                {({ selected, focus }) => (
                  <div
                    className={clsx(
                      'flex cursor-pointer items-center gap-3 rounded-lg p-2',
                      (selected || focus) && 'bg-gray-200'
                    )}
                  >
                    {item.initialElement}
                    <span className={clsx('grow truncate', selected ? 'font-bold' : 'font-normal')}>{item.label}</span>
                    {selected && (
                      <span className={clsx('pr-4')}>
                        <CheckIcon aria-hidden='true' className='size-5' />
                      </span>
                    )}
                  </div>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>

        {errors && (
          <ErrorMessage
            errors={errors}
            name={props.name}
            render={({ message }) => (
              <Text id={`${dataId}-error`} size='sm' className='mt-2 text-orange-700'>
                {message}
              </Text>
            )}
          />
        )}
      </Listbox>
    );
  }
);

Select.displayName = 'Select';

export default Select;
