import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';

interface AcceptTermsOfServiceRequestParams {
  id: number;
  assessmentId: string;
}

export const useAcceptTermsOfService = () => {
  const { getClient } = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestParams: AcceptTermsOfServiceRequestParams) => {
      const client = await getClient();

      await client.post(`/assessments/${requestParams.assessmentId}/terms-of-service/${requestParams.id}`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['terms-of-service'],
      });
    },
  });
};
