import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes } from 'react';

import FormFieldLabel from '@/components/FormFieldLabel';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  ['data-id']: string;
  label: string;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ label, 'data-id': dataId, ...props }, ref) => {
  return (
    <div className='items-top flex gap-x-3'>
      <input
        {...props}
        ref={ref}
        id={`${dataId}-field`}
        aria-labelledby={label ? `${dataId}-label` : undefined}
        type='checkbox'
        className={clsx(
          'size-4',
          'rounded',
          'base-form-field-outline',
          'base-form-field-disabled',
          'focus-input focus-only-input',
          'text-gray-700 focus:ring-0'
        )}
      />

      <FormFieldLabel data-id={dataId} label={label} htmlFor={`${dataId}-field`} />
    </div>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
