import { Auth0Provider } from '@auth0/auth0-react';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import UtilityDetailsBlock from './storyblokComponents/UtilityDetailsBlock';

import { ErrorBoundary } from '@/components/ErrorBoundary';
import AuthProvider from '@/contexts/AuthContext';
import Pages from '@/pages';
import { MeasureCategory, MeasureSubcategory, Measure } from '@/pages/Dashboard/components/measures';
import {
  ProgramSummaryBlock,
  ReportHighlightBlock,
  ProgramHighlightItem,
  ReportSummaryBlock,
  ReportSummaryItem,
  ProgramHighlightBlock,
  NextStepsBlock,
  NextStepsItem,
} from '@/pages/Dashboard/components/opportunities';
import EnergyUsageBlock from '@/storyblokComponents/EnergyUsageBlock';
import EnergyUsageItem from '@/storyblokComponents/EnergyUsageItem';
import ExpandableRichTextBlock from '@/storyblokComponents/ExpandableRichTextBlock';
import ResidentDashCard from '@/storyblokComponents/ResidentDashCard';
import ResidentDashSection from '@/storyblokComponents/ResidentDashSection';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const REDIRECT_URL = window.location.origin;
const audienceDomain = import.meta.env.VITE_AUTH0_AUDIENCE ?? import.meta.env.VITE_BACKEND_DOMAIN;

storyblokInit({
  accessToken: import.meta.env.VITE_STORYBLOK_PREVIEW_TOKEN,
  apiOptions: {
    region: 'us',
  },
  use: [apiPlugin],
  components: {
    ResidentDashCard,
    ResidentDashSection,
    EnergyUsageBlock,
    EnergyUsageItem,
    ProgramSummaryBlock,
    ReportHighlightBlock,
    ProgramHighlightItem,
    ReportSummaryBlock,
    ReportSummaryItem,
    ExpandableRichTextBlock,
    ProgramHighlightBlock,
    NextStepsBlock,
    NextStepsItem,
    MeasureCategory,
    MeasureSubcategory,
    Measure,
    UtilityDetailsBlock,
  },
});

const App = () => {
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: REDIRECT_URL,
        audience: `https://${audienceDomain}`,
      }}
      useCookiesForTransactions
    >
      <QueryClientProvider client={queryClient}>
        <Router>
          <ErrorBoundary>
            <AuthProvider>
              <Pages />
            </AuthProvider>
          </ErrorBoundary>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Auth0Provider>
  );
};

export default App;
