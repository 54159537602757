import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AssessmentFormButtons from './AssessmentFormButtons';
import AssessmentFormCoreFields from './AssessmentFormCoreFields';
import AssessmentFormIntro from './AssessmentFormIntro';
import AssessmentFormQuestionInfo from './AssessmentFormQuestionInfo';
import AssessmentFormQuestions from './AssessmentFormQuestions';
import { AssessmentFormSectionContent } from './hooks/types';
import { useAssessmentFormSubSections } from './hooks/useAssessmentFormSubSections';
import ShareUsageData from './ShareUsageData';

import { AssessmentSubSectionType } from '@/api';
import Text from '@/components/Typography';
import { useAssessmentContext } from '@/contexts/AssessmentContext';
import { useScrollToTop } from '@/hooks/useScrollToTop';

interface Props {
  currentSection: AssessmentFormSectionContent;
  canPrevSection: boolean;
  canNextSection: boolean;
}

const AssessmentFormSection: React.FC<Props> = ({ currentSection }) => {
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const {
    details: { assessmentId, affiliate, questions, propertyId, userId, results },
  } = useAssessmentContext();
  const { currentSubsection, onNextSubsection, onPreviousSubsection, subsections, canProceedToNextStep } =
    useAssessmentFormSubSections(currentSection, results);

  const subsection = currentSubsection ? subsections[currentSubsection] : undefined;

  // Scroll to top on re-render of the component
  useScrollToTop(subsection?.header);

  const canDoPrev = !!subsection?.newPrevSubsection || !!currentSection.prevSection;

  const info = subsection?.info?.[0];

  const renderSubSectionContent = (): JSX.Element | null => {
    if (subsection?.header === 'cover' && currentSection.cover) {
      return (
        <AssessmentFormIntro
          heading={currentSection.idx}
          title={currentSection.cover.header}
          icon={currentSection.cover.iconComponent}
          description={currentSection.cover.body}
          descriptionLabel={currentSection.cover.subheader}
          useCase={currentSection.cover.matters_text ?? ''}
        />
      );
    }
    if (subsection?.coreDataFields?.length || subsection?.questions?.length) {
      return (
        <>
          {info && (
            <AssessmentFormQuestionInfo
              showModal={infoModalOpen}
              setShowModal={setInfoModalOpen}
              title={info.title}
              subtitle={info.subtitle}
              document={info.description}
              images={info.graphics}
            />
          )}
          <div className='flex align-middle'>
            <Text as='h2' size='lg' className='font-semibold'>
              {subsection.header}
            </Text>
            {info ? (
              <span className='mx-2 mt-1'>
                <button onClick={() => setInfoModalOpen(true)}>
                  <InformationCircleIcon className='size-5 text-black' />
                </button>
              </span>
            ) : null}
          </div>
          {!!subsection.description && <Text className='mb-2'>{subsection.description}</Text>}
          {!!subsection.coreDataFields.length && (
            <AssessmentFormCoreFields
              propertyId={propertyId}
              userId={userId}
              affiliateId={affiliate.id}
              coreFields={subsection.coreDataFields}
              results={results}
            />
          )}
          {!!subsection.questions.length && (
            <AssessmentFormQuestions
              assessmentId={assessmentId}
              questions={subsection.questions}
              allQuestions={questions}
              results={results}
              sectionType={subsection.sectionType}
              resultIndex={subsection.resultIndex}
            />
          )}
        </>
      );
    }
    if (subsection?.sectionType === AssessmentSubSectionType.SHARE_USAGE_DATA) {
      return <ShareUsageData />;
    }
    // TODO (AIMEE): throw? or redirect?
    return null;
  };

  return (
    <>
      <div className='flex flex-col gap-2'>{renderSubSectionContent()}</div>
      <AssessmentFormButtons
        canPreviousStep={canDoPrev}
        disableNextStep={!canProceedToNextStep}
        nextLabel={subsection?.nextButtonLabel}
        onNextStep={() => {
          if (!subsection) return;

          if (subsection.overrideNextButtonAction) {
            subsection.overrideNextButtonAction();

            return;
          }

          onNextSubsection();
        }}
        onPreviousStep={() => {
          if (!subsection) return;
          if (currentSection.prevSectionSearchParams) {
            setSearchParams({
              currentSection: currentSection.prevSectionSearchParams.currentSection,
              currentSubsection: currentSection.prevSectionSearchParams.currentSubsection,
            });
          }

          onPreviousSubsection();
        }}
      />
    </>
  );
};

export default AssessmentFormSection;
