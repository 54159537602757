import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes, useRef } from 'react';

import Button from '@/components/Button';
import FormFieldLabel from '@/components/FormFieldLabel';

export interface PictureInputProps extends InputHTMLAttributes<HTMLInputElement> {
  ['data-id']: string;
  label?: string;
  errors?: Record<string, unknown>;
}

const PictureInput = forwardRef<HTMLInputElement, PictureInputProps>(
  ({ label, 'data-id': dataId, value, ...props }, _ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
      <div>
        <FormFieldLabel data-id={dataId} label={label} htmlFor={`${dataId}-field`} />
        <div id={`${dataId}-container`} className={clsx('relative flex gap-2', label && 'mt-2')}>
          <input
            ref={inputRef}
            id={`${dataId}-field`}
            aria-labelledby={label ? `${dataId}-label` : undefined}
            {...props}
            type='file'
            className={clsx('sr-only')}
          />
          {value && <img src={value as string} alt='Profile Picture' className='size-10 rounded-full object-cover' />}
          {!value && <div className='size-10 rounded-full bg-gray-600'></div>}
          <Button
            buttonStyle='light'
            buttonType='outline'
            size='sm'
            type='button'
            onClick={() => inputRef.current?.click()}
            disabled={props.disabled}
          >
            Upload
          </Button>
        </div>
      </div>
    );
  }
);

PictureInput.displayName = 'PictureInput';

export default PictureInput;
