import { AssetStoryblok } from '@maivenenergy/storyblok';
import { Dispatch, SetStateAction } from 'react';

import ModalDisplay from '@/components/ModalDisplay';
import Text from '@/components/Typography';

interface AssessmentFormQuestionInfoProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  title?: string;
  text?: string;
  graphic?: AssetStoryblok;
  programContactEmail?: string;
  programContactPhone?: string;
  programContactPhoneDisplay?: string;
}

const AssessmentFormQuestionError = ({
  showModal,
  setShowModal,
  title,
  text,
  graphic,
  programContactEmail,
  programContactPhone,
  programContactPhoneDisplay,
}: AssessmentFormQuestionInfoProps) => {
  const closeModalHandle = () => {
    setShowModal(false);
  };

  return (
    <ModalDisplay showModal={showModal} closeModalHandle={closeModalHandle}>
      <div className='flex flex-col px-4 py-2'>
        {graphic?.filename && (
          <img
            src={graphic.filename}
            alt='info-image'
            className='max-h-[180px] w-full rounded-2xl object-cover object-center'
          />
        )}
        {title ? <Text className='text-2xl font-bold'>{title}</Text> : null}
        {text ? <Text className='mt-2 text-base'>{text}</Text> : null}
        {programContactEmail && (
          <a href={`mailto:${programContactEmail}`} className='mt-4 underline'>
            {programContactEmail}
          </a>
        )}
        {programContactPhone && programContactPhoneDisplay && (
          <a href={`tel:${programContactPhone}`} className='mt-4 underline'>
            {programContactPhoneDisplay}
          </a>
        )}
      </div>
    </ModalDisplay>
  );
};

export default AssessmentFormQuestionError;
