import { MeasureCategoryStoryblok } from '@maivenenergy/storyblok';

interface MeasureCategoryProps {
  blok: MeasureCategoryStoryblok;
}

const MeasureCategory = ({ blok }: MeasureCategoryProps) => {
  return <div className='p-1 text-sm font-bold'>{blok.description}</div>;
};

export default MeasureCategory;
