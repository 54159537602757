// import { HomeIcon } from '@heroicons/react/24/solid';
import { ProgramHighlightBlockStoryblok } from '@maivenenergy/storyblok';
// import { StoryblokComponent } from '@storyblok/react';

import PurpleStroke from '@/assets/purple-stroke.svg';
// import HomeAddress from '@/components/HomeAddress';
import Text from '@/components/Typography';
// import { useUserProfileContext } from '@/contexts/UserProfileContext';

interface ReportHighlightBlockProps {
  blok: ProgramHighlightBlockStoryblok;
}

const ReportHighlightBlock = ({ blok }: ReportHighlightBlockProps) => {
  // const { profile } = useUserProfileContext();

  return (
    <div className='flex flex-col gap-4 bg-white p-4 pt-6'>
      <div className='flex flex-col gap-4 p-4 pt-6'>
        <Text className='inline-block w-fit bg-gradient-to-r from-purple-500 to-yellow-500 bg-clip-text text-2xl font-bold text-transparent'>
          {blok.title}
        </Text>
      </div>
      <div className='flex flex-col gap-4 p-4'>
        <img src={PurpleStroke} alt='purple stroke' className='w-6' />
        <Text className='text-2xl font-bold'>{blok.highlight}</Text>
      </div>
      {/* <StoryblokComponent blok={blok.report_summary[0]} key={blok.report_summary[0]._uid} />
      {profile.properties.map((property, index) => {
        return (
          <HomeAddress key={index} label={`${property.address.zip} ${property.address.address1}`} HeroIcon={HomeIcon} />
        );
      })} */}
    </div>
  );
};

export default ReportHighlightBlock;
