import { ProgramHighlightBlockStoryblok } from '@maivenenergy/storyblok';
import { StoryblokComponent } from '@storyblok/react';

interface ProgramHighlightBlockProps {
  blok: ProgramHighlightBlockStoryblok;
}

const ProgramHighlightBlock = ({ blok }: ProgramHighlightBlockProps) => {
  return (
    <div className='flex flex-col'>
      {blok.highlights?.map((highlight) => {
        return <StoryblokComponent blok={highlight} key={highlight._uid} />;
      })}
    </div>
  );
};

export default ProgramHighlightBlock;
