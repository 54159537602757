import React from 'react';

import GradientCover from '@/assets/gradient-cover.jpeg';
import Text from '@/components/Typography';

interface Props {
  heading: string;
  title: string;
  icon: React.ElementType;
  descriptionLabel: string;
  description: string;
  useCase: string | JSX.Element;
}

const AssessmentFormIntro: React.FC<Props> = ({
  description,
  descriptionLabel,
  heading,
  icon: IconComponent,
  title,
  useCase,
}) => {
  return (
    <div
      className='flex grow flex-col rounded-2xl bg-yellow-500 bg-cover bg-center p-6 md:min-h-[65dvh]'
      style={{ backgroundImage: `url('${GradientCover}')` }}
    >
      <div className='flex grow flex-col items-center justify-center gap-2 text-center'>
        <Text size='2xl' className='font-bold'>
          {heading}
        </Text>
        <Text size='4xl' className='w-3/5 font-bold'>
          {title}
        </Text>
        <span>
          <IconComponent className='size-16' />
        </span>
        <Text className='font-bold'>{descriptionLabel}</Text>
        <Text>{description}</Text>
      </div>
      {typeof useCase === 'string' && useCase ? (
        <div className='flex h-1/4 flex-col gap-2 rounded-b-2xl bg-yellow-500 p-4 sm:mb-5 sm:mr-2 sm:mt-7 sm:self-end sm:rounded-2xl md:w-1/2'>
          <div className='flex w-full items-center gap-4'>
            <Text className='grow font-bold'>Why this matters</Text>
            {/* Restore when we are ready */}
            {/* <Narrate link='/narrate' /> */}
          </div>
          <Text>{useCase}</Text>
        </div>
      ) : (
        useCase
      )}
    </div>
  );
};

export default AssessmentFormIntro;
