import React from 'react';

import Text from '@/components/Typography';

interface Props {
  title: string;
  subtitle?: string;
  description: string | JSX.Element;
  icon?: React.ReactNode;
}

const AssessmentSliderItem: React.FC<Props> = ({ title, subtitle, description, icon }) => {
  return (
    <div className='flex flex-col gap-2 px-10'>
      {icon}
      <Text as='h1' size='3xl' className='font-bold'>
        {title}
      </Text>
      {subtitle && (
        <Text size='base' className='mt-2 font-bold'>
          {subtitle}
        </Text>
      )}
      {typeof description === 'string' ? <Text className='mt-2 font-normal'>{description}</Text> : <>{description}</>}
    </div>
  );
};

export default AssessmentSliderItem;
