import { HomeIcon, ListBulletIcon, UserIcon, CurrencyDollarIcon } from '@heroicons/react/24/solid';
import { useLocation, Link } from 'react-router-dom';

const navItems = [
  { icon: HomeIcon, label: 'Home', path: '/dashboard' },
  { icon: CurrencyDollarIcon, label: 'Opportunities', path: '/dashboard/opportunities' },
  { icon: ListBulletIcon, label: 'Activity', path: '/dashboard/activity' },
  { icon: UserIcon, label: 'Profile', path: '/dashboard/profile' },
];

const BottomBar = () => {
  const location = useLocation();

  return (
    <nav className='fixed inset-x-0 bottom-0 z-10 w-full border-t border-gray-200 bg-white'>
      <div className='flex items-center justify-around py-2'>
        {navItems.map(({ icon: Icon, label, path }) => {
          const isActive = location.pathname === path;

          return (
            <Link
              key={path}
              to={path}
              className={`flex flex-col items-center gap-0.5 ${isActive ? 'text-purple-500' : 'text-gray-700'}`}
            >
              <Icon className='size-5' />
              <span className='text-sm'>{label}</span>
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default BottomBar;
