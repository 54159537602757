import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { AssessmentDetails } from '@/api/types';

export const useGetAssessmentDetails = () => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: ['assessment-details'] as const,
    queryFn: async () => {
      const client = await getClient();

      const response = await client.get<AssessmentDetails>('/assessments/assessmentId/current');

      return response.data;
    },
  });
};
