import React, { FC } from 'react';

import SVGIcon, { SVGIconProps } from '@/components/SVGIcon';

const MaivenIconDark: FC<SVGIconProps> = ({ width = '48', height = '48', viewBox = '0 0 48 48' }) => {
  return (
    <SVGIcon width={width} height={height} viewBox={viewBox}>
      <path
        d='M47.9987 0L0 30.1933V59.9972C4.40544 52.0201 8.46381 44.9941 12.3119 39.5361C14.9871 35.7423 17.4039 32.9375 19.7041 30.9571C22.7694 28.3192 25.7309 27.0369 28.7633 27.0369C31.4283 27.0369 33.759 27.5908 35.6868 28.6834C37.6299 29.7836 39.16 31.4099 40.2316 33.5192C42.048 37.0904 42.2937 41.648 42.2937 45.6314L42.0404 45.6415H42.2937C42.2937 46.4812 42.2886 47.2906 42.281 48.067C42.2557 50.8289 42.2329 53.1987 42.6382 55.126C42.8789 56.2742 43.1652 56.6485 43.2234 56.7143C43.2741 56.732 43.421 56.7674 43.7124 56.7674C44.6928 56.7674 45.8226 55.8746 47.1602 54.0334C48.7993 51.7824 50.299 48.7044 52.0394 45.1433L52.3484 44.5085C53.9419 41.2458 56.1231 36.7793 58.8287 33.418C62.2334 29.1842 66.2006 27.0394 70.6187 27.0394C73.2407 27.0394 75.5536 27.7501 77.4941 29.1513C79.1864 30.3729 80.529 32.0877 81.4866 34.2451C82.7609 37.1157 83.379 40.8437 83.379 45.639V66.5553H74.0868V45.639C74.0868 42.212 73.7169 39.6423 72.9899 38.0034C72.7062 37.3635 72.387 36.9133 72.045 36.6655C71.9259 36.5795 71.5611 36.3139 70.6187 36.3139C69.0962 36.3139 67.6927 37.2118 66.0739 39.225C64.179 41.5822 62.4513 44.9915 60.7033 48.5729L60.2904 49.4201C58.664 52.7586 56.8223 56.5423 54.6816 59.4863C51.4694 63.8971 47.8822 66.0444 43.7149 66.0444C40.8016 66.0444 38.2961 65.0201 36.4671 63.0802C35.0383 61.5652 34.0832 59.5874 33.5462 57.0304C32.9103 54.0106 32.9559 50.3736 32.9888 47.4524C32.9964 46.7999 33.004 46.1954 33.004 45.6491C33.004 41.6505 32.6696 39.134 31.9476 37.7176C31.6386 37.1106 31.3346 36.883 31.104 36.754C30.7519 36.5542 30.0603 36.3165 28.7658 36.3165C28.5201 36.3165 27.5726 36.4379 25.774 37.9857C24.0842 39.44 22.1133 41.7593 19.9144 44.8777C15.889 50.5886 11.3619 58.6086 7.06795 66.4263L6.99702 66.5578H0V79.267H47.1475L73.9171 96V79.267H96V30.1933L47.9987 0Z'
        fill='black'
      />
    </SVGIcon>
  );
};

export default MaivenIconDark;
