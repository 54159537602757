import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { forwardRef } from 'react';

interface CarouselControlProps {
  onClick: () => void;
  action: 'next' | 'prev';
}

const CarouselControl = forwardRef<HTMLButtonElement, CarouselControlProps>(({ onClick, action }, ref) => {
  return (
    <button
      ref={ref}
      type='button'
      className={clsx(
        'flex-middle-middle group absolute top-0 z-30 hidden h-full cursor-pointer px-2 focus:outline-none md:flex',
        {
          'end-0': action === 'next',
          'start-0': action === 'prev',
        }
      )}
      onClick={onClick}
    >
      <span className='flex-middle-middle group inline-flex size-7 rounded-full focus:outline-none group-focus:ring-2 group-focus:ring-purple-500 dark:group-focus:ring-purple-500'>
        {action === 'prev' && <ChevronLeftIcon className='text-gray-50 hover:text-gray-400' />}
        {action === 'next' && <ChevronRightIcon className='text-gray-50 hover:text-gray-400' />}
        <span className='sr-only'>Previous</span>
      </span>
    </button>
  );
});

CarouselControl.displayName = 'CarouselControl';

export default CarouselControl;
