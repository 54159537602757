import { ResidentDashCardStoryblok } from '@maivenenergy/storyblok';

import ImageTextModalDisplay from '@/components/ImageTextModalDisplay';

interface ResidentDashCardProps {
  blok: ResidentDashCardStoryblok;
}

const ResidentDashCard: React.FC<ResidentDashCardProps> = ({ blok }) => {
  return (
    <ImageTextModalDisplay
      triggerImage={blok.graphic?.filename ?? ''}
      triggerText={blok.teaser_text}
      richText={blok.content}
      title={blok.title}
      subtitle={blok.subtitle ?? ''}
      modalImage={blok.graphic?.filename ?? ''}
    />
  );
};

export default ResidentDashCard;
