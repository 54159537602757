import { NextStepsItemStoryblok } from '@maivenenergy/storyblok';

import Text from '@/components/Typography';

interface NextStepsItemProps {
  blok: NextStepsItemStoryblok;
}

const NextStepsItem = ({ blok }: NextStepsItemProps) => {
  return (
    <div className='flex border-t border-gray-900'>
      <Text className='flex items-center justify-center gap-[10px] p-4 text-2xl font-bold'>
        {String(blok.index).padStart(2, '0')}
      </Text>
      <div className='flex flex-col gap-3 p-4 px-3'>
        <Text className='text-sm font-bold'>{blok.title}</Text>
        <Text className='text-xs text-gray-700'>{blok.description}</Text>
      </div>
    </div>
  );
};

export default NextStepsItem;
