import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import { forwardRef, TextareaHTMLAttributes } from 'react';

import FormFieldLabel from '@/components/FormFieldLabel';
import Text from '@/components/Typography';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  ['data-id']: string;
  label?: string;
  errors?: Record<string, unknown>;
  hasinfo?: boolean;
  onClickInfo?: Dispatch<SetStateAction<boolean>>;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, 'data-id': _dataId, errors = {}, hasinfo, onClickInfo, ...props }, ref) => {
    const hasError = !!errors[props.name ?? ''];
    const dataId = _dataId ?? props.name ?? 'input';

    return (
      <div id={dataId}>
        <FormFieldLabel
          data-id={dataId}
          label={label}
          htmlFor={`${dataId}-field`}
          hasInfo={hasinfo}
          onClickInfo={onClickInfo}
        />
        <div id={`${dataId}-container`} className={clsx('relative', label && 'mt-2')}>
          <textarea
            ref={ref}
            id={`${dataId}-field`}
            aria-labelledby={label ? `${dataId}-label` : undefined}
            {...props}
            className={clsx(
              'base-form-field block pr-10',
              'base-form-field-outline',
              'base-form-field-disabled',
              'focus-input',
              hasError && 'outline-orange-700 placeholder:text-orange-300 focus-visible:outline-orange-700'
            )}
          />

          {hasError && (
            <div
              id={`${dataId}-state-icon-container`}
              className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'
            >
              <ExclamationCircleIcon aria-hidden='true' className='size-5 text-orange-700' />
            </div>
          )}
        </div>

        {errors && (
          <ErrorMessage
            errors={errors}
            name={props.name ?? ''}
            render={({ message }) => (
              <Text id={`${dataId}-error`} size='sm' className='mt-2 text-orange-700'>
                {message}
              </Text>
            )}
          />
        )}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
