import { useEnrollment } from '.';

import { useGetScopeOfWork } from '@/api/hooks/queries/useGetScopeOfWork';
import CostSavingsTable from '@/components/CostSavingsTable';
import Text from '@/components/Typography';

const ScopeOfWork = () => {
  const { programName, recommendationStrategy } = useEnrollment();
  const { data, isLoading, isError } = useGetScopeOfWork(recommendationStrategy);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (isError || !data) {
    return <Text>Something went wrong. Please contact the system administrator</Text>;
  }

  const formatCurrency = (amount: number) => {
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className='flex flex-col gap-4 p-8'>
      <Text className='text-lg font-bold leading-none'>{programName} </Text>
      <div className='pt-6'>
        <Text className='text-2xl font-bold leading-none'>{data?.title}</Text>
      </div>
      <div className='pt-2'>
        <Text className='text-sm'>{data?.description}</Text>
      </div>
      <Text className='mt-8 font-bold'>Required Meaures</Text>
      <CostSavingsTable measures={data.prereqs} incentives={data.prereqIncentives} />
      <Text className='mt-8 font-bold'>
        Your Personalized Recommendations
        <br />
        (What You Get & The Incentives We Found You)
      </Text>
      <div className='relative'>
        <div className='absolute inset-0 flex items-center' aria-hidden='true'>
          <div className='w-full border-t border-gray-300'></div>
        </div>
      </div>
      <Text className='text-lg font-bold'>&#42;Bottom Line</Text>
      <table className='w-full'>
        <tbody className='divide-y divide-gray-200'>
          <tr>
            <td className='py-2 text-sm font-semibold text-gray-800'>Total Project Cost</td>
            <td className='py-2 text-right text-sm font-semibold text-gray-800'>${formatCurrency(data.totalCost)}</td>
          </tr>
          <tr>
            <td className='py-2 text-sm font-semibold text-gray-800'>Incentive Total</td>
            <td className='py-2 text-right text-sm font-semibold text-gray-800'>
              ${formatCurrency(data.totalIncentives)}
            </td>
          </tr>
          <tr className='bg-gray-50'>
            <td className='py-2 text-sm font-bold text-gray-900'>Est. Out of Pocket</td>
            <td className='py-2 text-right text-sm font-bold text-gray-900'>
              ${formatCurrency(Math.max(0, data.outOfPocket))}
            </td>
          </tr>
        </tbody>
      </table>
      <div className='mt-6'>
        <CostSavingsTable measures={data.measures} incentives={data.incentives} />
      </div>
      <Text className='pt-4 text-sm font-bold'>{data?.disclaimer}</Text>
    </div>
  );
};

export default ScopeOfWork;
