import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { RecommendationStrategy } from '@/api/types';

interface AcceptPPARequestParams {
  assessmentId: string;
  recommendationStrategy: RecommendationStrategy;
}

export const useAcceptPPA = () => {
  const { getClient } = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestParams: AcceptPPARequestParams) => {
      const client = await getClient();

      await client.post(
        `/assessments/${requestParams.assessmentId}/ppa?recommendationStrategy=${requestParams.recommendationStrategy}`
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['ppa'],
      });
    },
  });
};
