import React, { FC } from 'react';

import CustomIcons from '../CustomIcons';
import { SVGIconProps } from '../SVGIcon';

const VARIANTS = {
  WORDMARK: 'wordmark',
  ICON: 'icon',
  AVATAR: 'avatar',
};

type LogoVariants = 'wordmark' | 'icon' | 'avatar';
// TODO (AIMEE): extend to support 'logomarkVertical', 'logomarkHorizontal'

const COLORS = {
  DARK: 'dark',
  LIGHT: 'light',
  GRADIENT: 'gradient',
  GRADIENT_DARK: 'gradientDark',
  GRADIENT_LIGHT: 'gradientLight',
};

type LogoColors = 'dark' | 'light' | 'gradient' | 'gradientDark' | 'gradientLight';

interface Props {
  variant: LogoVariants;
  color?: LogoColors;
  // TODO (AIMEE: extend to pass height, width, and viewBox to <Logo />
}

const logos: {
  wordmark: {
    dark: FC<SVGIconProps>;
  };
  icon: {
    gradient: FC<SVGIconProps>;
    dark: FC<SVGIconProps>;
    light: FC<SVGIconProps>;
  };
  avatar: {
    gradientDark: FC<SVGIconProps>;
    gradientLight: FC<SVGIconProps>;
  };
} = {
  wordmark: {
    dark: CustomIcons.MaivenWordmark,
  },
  icon: {
    gradient: CustomIcons.MaivenIconGradient,
    dark: CustomIcons.MaivenIconDark,
    light: CustomIcons.MaivenIconLight,
  },
  avatar: {
    gradientDark: CustomIcons.MaivenAvatarGradientDark,
    gradientLight: CustomIcons.MaivenAvatarGradientLight,
  },
};

const MaivenLogo: React.FC<Props> = ({ variant, color = 'dark' }) => {
  const getLogo = () => {
    switch (variant + color) {
      case VARIANTS.WORDMARK + COLORS.DARK:
        return logos.wordmark.dark;
      case VARIANTS.ICON + COLORS.GRADIENT:
        return logos.icon.gradient;
      case VARIANTS.ICON + COLORS.DARK:
        return logos.icon.dark;
      case VARIANTS.ICON + COLORS.LIGHT:
        return logos.icon.light;
      case VARIANTS.AVATAR + COLORS.GRADIENT_DARK:
        return logos.avatar.gradientDark;
      case VARIANTS.AVATAR + COLORS.GRADIENT_LIGHT:
        return logos.avatar.gradientLight;
      default:
        return logos.wordmark.dark;
    }
  };
  const Logo = getLogo();
  return <Logo />;
};

export default MaivenLogo;
