import { StoryblokComponent } from '@storyblok/react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useGetOpportunityDetailStory } from '@/api';
import Button from '@/components/Button';
import Tag from '@/components/Tag';
import Text from '@/components/Typography';
import BottomBar from '@/layouts/ApplicationLayout/BottomBar';
import { logger } from '@/lib/Logger';

const OpportunitiesDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { recommendationStrategy, detailsSlug } = location.state ?? {};
  const { data, isLoading, error } = useGetOpportunityDetailStory(detailsSlug, recommendationStrategy);

  useEffect(() => {
    if (!recommendationStrategy) {
      navigate('/dashboard/opportunities');
    }
  }, [navigate, recommendationStrategy]);

  if (isLoading) {
    return null;
  }

  const errorState = !data || error;

  if (errorState) {
    logger.error('Failed to fetch dashboard stories', { error });
    return <Text>Something wrong happened. Try again</Text>;
  }

  return (
    <div className='relative flex h-full flex-col'>
      <div className='flex-1 overflow-y-auto pb-24'>
        <div className='flex justify-start gap-2 bg-white px-6 py-2'>
          <Tag label='Big Savings' icon='tag' featured />
          <Tag label='Energy Program' icon='none' />
          <Tag label='Enrollment' icon='none' />
        </div>
        <div className='mt-6 h-48 px-6'>
          {data.story.content?.header_image?.filename && (
            <img
              src={data.story.content.header_image.filename}
              alt='program-summary'
              className='size-full rounded-lg object-cover'
            />
          )}
        </div>
        <div className='mt-6 px-6'>
          <Text className='text-2xl font-bold'>{data.story.content.title}</Text>
          <Text className='mt-2'>{data.story.content.description}</Text>
        </div>
        {data.story.content.sections?.map((section, index) => {
          return typeof section === 'string' ? null : (
            <div key={section.uuid}>
              <StoryblokComponent blok={section.content} />
            </div>
          );
        })}
      </div>
      <div className='fixed inset-x-0 bottom-[58px] flex flex-col items-center justify-center bg-white p-4 shadow-lg'>
        <Button
          size='xl'
          className='mx-auto'
          onClick={() => {
            navigate('/dashboard/enrollment', {
              state: {
                recommendationStrategy: recommendationStrategy,
              },
            });
          }}
        >
          Enroll
        </Button>
        <BottomBar />
      </div>
    </div>
  );
};

export default OpportunitiesDetails;
