import React from 'react';

import Text from '@/components/Typography';

export interface IncentiveRowProps {
  incentiveType: string;
  incentiveQualifier: string;
  amount: number;
  savePercentage?: number;
  className?: string;
}

const IncentiveRow: React.FC<IncentiveRowProps> = ({
  incentiveType,
  incentiveQualifier,
  amount,
  savePercentage,
  className,
}) => {
  const isCarbon = incentiveType.toLowerCase().includes('carbon');
  const formattedAmount = isCarbon
    ? `${Math.round(amount * 100) / 100}`
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(amount);

  return (
    <div className={`flex flex-col gap-2 transition-colors ${className ?? ''}`}>
      <div className='flex items-center justify-between'>
        <Text as='span' size='base' className='font-bold text-gray-900'>
          {incentiveType}
        </Text>
        <Text as='span' size='base' className='font-bold text-gray-900'>
          {formattedAmount}
        </Text>
      </div>
      <div className='flex items-center justify-between'>
        <Text as='span' size='xs' className='text-gray-500'>
          {incentiveQualifier}
        </Text>
        {/* <Tag label={`Save ${savePercentage}%`} /> */}
      </div>
    </div>
  );
};

export default IncentiveRow;
