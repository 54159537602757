import { useMutation } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';

interface ValidateAddressParams {
  address: string;
  affiliateId: string;
}

interface ValidateAddressResponse {
  isValid: boolean;
  zoneId?: string;
  zoneName?: string;
}

export const useValidateAddress = () => {
  const { getClient } = useApiClient();

  return useMutation({
    mutationFn: async (params: ValidateAddressParams) => {
      const client = await getClient();

      const { data } = await client.post<ValidateAddressResponse>('/zones/validate', params);

      return data;
    },
  });
};
