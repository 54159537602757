import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AuthContext, { AuthContextValue } from './context';

import Button from '@/components/Button';
import Text from '@/components/Typography';

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    logout,
    loginWithRedirect,
    isLoading: isLoadingAuthInstance,
    isAuthenticated,
    user,
    error: auth0Error,
  } = useAuth0();
  const { pathname, search } = useLocation();

  const handleLogout = useCallback(() => {
    void logout({ logoutParams: { returnTo: window.location.origin } }).catch(() => null);
  }, [logout]);

  const handleLoginWithRedirect = useCallback(() => {
    const preventRedirectionRoutes = ['/login', '/logout', '/'];
    const loginRedirection = new URL('/login', window.location.origin);
    const extraParams: Record<string, string> = {};

    if (!preventRedirectionRoutes.includes(pathname)) {
      const currentUrl = new URL(pathname + search, window.location.origin);

      loginRedirection.searchParams.append('redirect', currentUrl.toString());
    }

    if (pathname === '/login') {
      const allowedRedirectParams = ['page', 'redirect'];
      const searchParams = new URLSearchParams(search);

      allowedRedirectParams.forEach((param) => {
        if (searchParams.has(param)) loginRedirection.searchParams.append(param, searchParams.get(param)!);
      });
    }

    void loginWithRedirect({
      authorizationParams: {
        redirect_uri: loginRedirection.toString(),
        screen_hint: 'signup',
        ...extraParams,
        connection: 'email',
      },
    });
  }, [loginWithRedirect, pathname, search]);

  useEffect(() => {
    if (!isLoadingAuthInstance && !isAuthenticated && !auth0Error) {
      handleLoginWithRedirect();
    }
  }, [user, isAuthenticated, handleLoginWithRedirect, isLoadingAuthInstance, auth0Error]);

  if (auth0Error?.message) {
    return (
      <div>
        <Text>Something went wrong</Text>
        <Text>Please contact support and provide the following error code:</Text>
        <pre>{auth0Error.message}</pre>

        <Button onClick={handleLogout}>Reload</Button>
      </div>
    );
  }

  if (isLoadingAuthInstance) {
    return <Text>Loading...</Text>;
  }

  const contextValue: AuthContextValue = {
    handleLogout,
    handleLoginWithRedirect,
    isAuthenticated,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
