import clsx from 'clsx';
import React, { useMemo } from 'react';

import AssessmentFormQuestion from './AssessmentFormQuestion';
import {
  AssessmentFormSubSectionQuestion,
  AssessmentFormSubSectionQuestionGroup,
} from './hooks/useAssessmentFormSubSections';

import { AssessmentResult, StoryblokAssessmentQuestion } from '@/api';
import Text from '@/components/Typography';

interface Props {
  assessmentId: string;
  questions: AssessmentFormSubSectionQuestion[] | AssessmentFormSubSectionQuestionGroup[];
  allQuestions: Record<string, StoryblokAssessmentQuestion>;
  results: Record<string, AssessmentResult>;
  sectionType?: string;
  resultIndex?: number;
}

const AssessmentFormQuestions: React.FC<Props> = ({
  assessmentId,
  questions,
  allQuestions,
  results,
  sectionType,
  resultIndex,
}) => {
  const questionGroups: AssessmentFormSubSectionQuestionGroup[] = useMemo(() => {
    if (!sectionType) return [];
    return questions as AssessmentFormSubSectionQuestionGroup[];
  }, [questions, sectionType]);

  const renderGroups: boolean = !!sectionType && !!questionGroups.length;
  return (
    <div id='assessment-form-questions' className={clsx('flex flex-col gap-3', renderGroups && 'mx-4')}>
      {renderGroups && (
        <div className='flex flex-col gap-12 bg-gray-50 px-4 py-3'>
          {questionGroups?.map((questionGroup) => (
            <div key={questionGroup?.name} className='flex flex-col gap-2'>
              <Text as='label' className='font-bold text-gray-700'>
                {questionGroup.display}
              </Text>
              <div className='flex flex-col gap-2'>
                {questionGroup.questions.map((question) => {
                  return (
                    <AssessmentFormQuestion
                      key={question.question_id}
                      assessmentId={assessmentId}
                      questionId={question.question_id}
                      dependingQuestions={question.dependingQuestions}
                      question={question}
                      result={results[question.question_id]}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      )}
      {!renderGroups &&
        (questions as AssessmentFormSubSectionQuestion[]).map((question) => {
          return (
            <AssessmentFormQuestion
              key={question.question_id}
              assessmentId={assessmentId}
              questionId={question.question_id}
              dependingQuestions={question.dependingQuestions}
              question={question}
              result={results[question.question_id]}
              resultIndex={resultIndex}
              filterOnQuestion={question.filterOnQuestion}
              filterOnResult={
                question.filterOnQuestion?.question_id ? results[question.filterOnQuestion.question_id] : undefined
              }
            />
          );
        })}
    </div>
  );
};

export default AssessmentFormQuestions;
