import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '@/contexts/AuthContext';

const Login = () => {
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/terms');
    }
  }, [isAuthenticated, navigate]);

  return null;
};

export default Login;
