import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React, { useState } from 'react';

import Text from '@/components/Typography';

interface CollapsibleCardProps {
  heading: string | JSX.Element;
}

const CollapsibleCard: React.FC<React.PropsWithChildren<CollapsibleCardProps>> = ({ heading, children }) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className={clsx(
        'base-form-field',
        'base-form-field-outline',
        'base-form-field-disabled',
        'focus-input',
        'flex flex-col gap-3 bg-white p-4'
      )}
    >
      <div className='flex items-center justify-between gap-3'>
        {typeof heading === 'string' ? (
          <Text as='label' className='grow font-bold text-gray-900'>
            {heading}
          </Text>
        ) : (
          heading
        )}
        <span className='size-5 cursor-pointer text-gray-800 hover:text-gray-400' onClick={() => setShow((s) => !s)}>
          {show && <ChevronUpIcon />}
          {!show && <ChevronDownIcon />}
        </span>
      </div>
      <Transition show={show}>
        <div className={clsx('flex flex-col gap-3 transition duration-300 ease-in-out data-[closed]:opacity-0')}>
          {children}
        </div>
      </Transition>
    </div>
  );
};

export default CollapsibleCard;
