import { StoryblokComponent } from '@storyblok/react';

import { useGetOpportunitiesStory } from '@/api';
import CompanyLogoFooter from '@/components/CompanyLogoFooter';
import Text from '@/components/Typography';
import { logger } from '@/lib/Logger';

const Opportunities = () => {
  const { data, isLoading, error } = useGetOpportunitiesStory();

  if (isLoading) {
    return null;
  }

  const errorState = !data || error;

  if (errorState) {
    logger.error('Failed to fetch opportunities stories', { error });
    return <Text>Something wrong happened. Try again</Text>;
  }

  return (
    <div className='flex flex-col bg-gray-100'>
      <div className='flex flex-col gap-4'>
        {data.story.content.sections?.map((section, index) => {
          let className = '';
          if (index === 1) className = 'p-0 pr-8 pl-8';
          return typeof section === 'string' ? null : (
            <StoryblokComponent blok={{ ...section.content, className }} key={section.uuid} />
          );
        })}
      </div>
      <CompanyLogoFooter />
    </div>
  );
};

export default Opportunities;
