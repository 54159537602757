import { HomeIcon } from '@heroicons/react/24/outline';
import { CameraIcon, UserCircleIcon, QuestionMarkCircleIcon, LightBulbIcon } from '@heroicons/react/24/solid';
import { useMemo, useState } from 'react';

import { AssessmentFormSectionStatus, AssessmentFormSection, AssessmentFormSectionContentByName } from './types';
import { useAssessmentFormReviewAndSubmitSection } from './useAssessmentFormReviewAndSubmitSection';

import { AssessmentResult, ProfileType, StoryblokAssessmentSubSection, StoryblokAssessmentSection } from '@/api';
import AirVentIcon from '@/assets/air-vent.svg?react';
import GarageIcon from '@/assets/garage.svg?react';
import HomeOuterIcon from '@/assets/home-outer.svg?react';
import { useAssessmentContext } from '@/contexts/AssessmentContext';

const AssessmentFormSectionsIcon: Record<string, React.ElementType> = {
  user_circle: UserCircleIcon,
  camera: CameraIcon,
  home: HomeIcon,
  car: GarageIcon,
  garage: GarageIcon,
  car_house: GarageIcon,
  home_outer: HomeOuterIcon,
  air_vent: AirVentIcon,
  light_bulb: LightBulbIcon,
};

const resolveSubsectionDependencies = (
  subsections: StoryblokAssessmentSubSection[],
  allResults: Record<string, AssessmentResult>,
  userProfile: ProfileType
) => {
  return subsections.reduce<StoryblokAssessmentSubSection[]>((acc, c) => {
    // TODO (Aimee): Comment this back in when MI no longer needs both synced and manual usage inputs
    // if (c.depends_on_value === SHARED_USAGE_DATA_DEPENDS_ON_VALUE && userProfile?.shared_usage_data) {
    //   return acc;
    // }
    const [dependsOn] = c.depends_on ?? [];
    if (dependsOn && c.depends_on_value) {
      const result = allResults[dependsOn];
      const hasValue =
        result?.value === c.depends_on_value || result?.values.some((r) => r.value === c.depends_on_value);

      if (!hasValue) {
        return acc;
      }
    }

    acc.push(c);

    return acc;
  }, []);
};

const getSectionProgress = (
  subsections: StoryblokAssessmentSubSection[],
  results: Record<string, AssessmentResult>
): {
  status: AssessmentFormSectionStatus;
  percentage: number;
  answered: number;
  totalCount: number;
  notAnsweredSectionsNames: string[];
} => {
  const { answered, totalCount, notAnsweredSectionsNames } = subsections.reduce<{
    answered: number;
    totalCount: number;
    notAnsweredSectionsNames: string[];
  }>(
    (acc, subsection) => {
      subsection.questions?.forEach((q, _, arr) => {
        const [parentQuestionId] = q?.depends_on ?? [];
        const dependingResult = results[parentQuestionId];

        // TODO: can we just convert depends on value to an array in storyblok?
        const dependsOnValues = Array.isArray(q?.depends_on_value)
          ? q?.depends_on_value
          : q?.depends_on_value
            ? [q.depends_on_value]
            : [];

        if (
          (results[q?.question_id] && (results[q.question_id].value || !!results[q.question_id].values.length)) ||
          dependsOnValues.every((value) => value !== dependingResult?.value)
        ) {
          acc.answered += 1;
        } else {
          acc.notAnsweredSectionsNames.push(subsection.header);
        }

        acc.totalCount += 1;
      });

      subsection.core_data_fields?.forEach((q) => {
        if (
          results[q.data_field_name] &&
          (results[q.data_field_name].value || !!results[q.data_field_name].values.length)
        ) {
          acc.answered += 1;
        }

        acc.totalCount += 1;
      });

      return acc;
    },
    { answered: 0, totalCount: 0, notAnsweredSectionsNames: [] }
  );

  return {
    status: answered === totalCount ? 'complete' : answered ? 'incomplete' : 'not-started',
    percentage: totalCount ? answered / totalCount : 0,
    answered,
    totalCount,
    notAnsweredSectionsNames,
  };
};

const getPrevSectionSearchParams = (
  prevSection?: StoryblokAssessmentSection
):
  | {
      currentSection: string;
      currentSubsection: string;
    }
  | undefined => {
  if (!prevSection) return;

  const lastSubsectionPoistion = prevSection.subsections.length - 1;
  const lastSubsection = prevSection.subsections[lastSubsectionPoistion];

  if (!lastSubsection) return;

  return {
    currentSection: prevSection.section_name,
    currentSubsection: lastSubsectionPoistion + lastSubsection.header + (lastSubsection.repeats_on?.length ? '0' : ''),
  };
};

export const useAssessmentFormSections = (userProfile: ProfileType) => {
  const {
    details: {
      results,
      template: { content },
    },
  } = useAssessmentContext();
  const { getAssessmentFormAndSubmitSection } = useAssessmentFormReviewAndSubmitSection();

  const { sectionNames, sections } = useMemo(() => {
    const _sections = content.sections.reduce<{
      sectionNames: AssessmentFormSection[];
      sections: AssessmentFormSectionContentByName;
    }>(
      (acc, cur, idx, arr) => {
        const iconComponent = AssessmentFormSectionsIcon[cur.icon] ?? QuestionMarkCircleIcon;
        const nextSection = idx < arr.length - 1 ? arr[idx + 1].section_name : undefined;
        const prevSection = idx > 0 ? arr[idx - 1] : undefined;
        const sectionProgress = getSectionProgress(cur.subsections, results);

        if (prevSection) {
          prevSection.subsections = resolveSubsectionDependencies(prevSection.subsections, results, userProfile);
        }
        const subsections = resolveSubsectionDependencies(cur.subsections, results, userProfile);

        acc.sectionNames.push({
          id: cur.section_name,
          ...sectionProgress,
        });
        acc.sections[cur.section_name] = {
          idx: (idx + 1).toString().padStart(2, '0'),
          title: cur.section_header,
          cover: {
            header: cur.section_header,
            icon: cur.icon,
            subheader: cur.sub_header,
            body: cur.body,
            matters_text: cur.matters_text,
            iconComponent,
          },
          canPrevSection: !!prevSection,
          prevSection: prevSection?.section_header,
          prevSectionSearchParams: prevSection ? getPrevSectionSearchParams({ ...prevSection }) : undefined,
          nextSection,
          canNextSection: !!nextSection,
          subsections,
        };
        return acc;
      },
      { sectionNames: [], sections: {} }
    );

    const lastSection = _sections.sectionNames[_sections.sectionNames.length - 1];

    if (lastSection) {
      _sections.sections[lastSection.id].nextSection = 'summary';
      _sections.sections[lastSection.id].canNextSection = true;
    }

    const { sectionContent, sectionName } = getAssessmentFormAndSubmitSection(
      _sections.sectionNames,
      _sections.sections,
      lastSection
    );

    _sections.sectionNames.push(sectionName);
    _sections.sections.summary = sectionContent;

    return _sections;
  }, [content.sections, results, getAssessmentFormAndSubmitSection, userProfile]);

  const [currentSection, setCurrentSection] = useState<string | undefined>(content.sections[0]?.section_name);

  return {
    sectionNames,
    sections: sections,
    currentSection,
    setCurrentSection: (str: string) => setCurrentSection(str),
  };
};
