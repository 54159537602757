import { forwardRef, Dispatch, InputHTMLAttributes, SetStateAction } from 'react';

import FormFieldLabel from '@/components/FormFieldLabel';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  ['data-id']: string;
  label?: string;
  errors?: Record<string, unknown>;
  hasinfo?: boolean;
  onClickInfo?: Dispatch<SetStateAction<boolean>>;
}

const DatePicker = forwardRef<HTMLInputElement, InputProps>(
  ({ label, 'data-id': _dataId, errors = {}, hasinfo, onClickInfo, ...props }, ref) => {
    return (
      <div>
        <FormFieldLabel
          data-id={_dataId}
          label={label}
          htmlFor={`${_dataId}-field`}
          hasInfo={hasinfo}
          onClickInfo={onClickInfo}
        />
        <div>
          <input ref={ref} id={`${_dataId}-field`} type='date' className='w-full rounded-md' {...props} />
        </div>
      </div>
    );
  }
);

DatePicker.displayName = 'DatePicker';

export default DatePicker;
