import { useEffect, DependencyList } from 'react';

type ScrollOptions = {
  behavior?: 'auto' | 'smooth';
  delay?: number;
};

/**
 * A hook that scrolls the window to the top based on optional dependencies
 * @param dependencies - Optional dependency or array of dependencies that trigger scroll
 * @param options - Optional configuration object for scroll behavior
 * @example
 * // Scroll on mount
 * useScrollToTop();
 *
 * // Scroll when data changes
 * useScrollToTop(data);
 *
 * // Scroll with multiple dependencies
 * useScrollToTop([data, filter]);
 *
 * // Scroll with custom options
 * useScrollToTop(data, { behavior: 'auto', delay: 100 });
 */
export const useScrollToTop = (
  dependencies?: null | string | DependencyList,
  options: ScrollOptions = { behavior: 'smooth' }
) => {
  useEffect(
    () => {
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: options.behavior || 'smooth',
        });
      };

      if (options.delay) {
        const timeoutId = setTimeout(scrollToTop, options.delay);
        return () => clearTimeout(timeoutId);
      } else {
        scrollToTop();
      }
    },
    dependencies === null ? [] : Array.isArray(dependencies) ? dependencies : [dependencies]
  );
};
