import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { AssessmentDetails, AssessmentResultType } from '@/api/types';

interface PatchServiceAccountRequestBody {
  coreField: string;
  reportOnId: string;
}

export const usePatchServiceAccount = () => {
  const { getClient } = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestBody: PatchServiceAccountRequestBody) => {
      const client = await getClient();

      await client.patch('/service-accounts', {
        reportOnId: requestBody.reportOnId,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(['assessment-details'], (old?: AssessmentDetails): AssessmentDetails | undefined => {
        return old
          ? {
              ...old,
              results: {
                ...old.results,
                [variables.coreField]: {
                  id: old.results[variables.coreField]?.id ?? `new-response-${Date.now()}`,
                  value: variables.reportOnId,
                  assessmentQuestionId: variables.coreField,
                  type: AssessmentResultType.USER_PROVIDED,
                  values: [],
                },
              },
            }
          : old;
      });
    },
  });
};
