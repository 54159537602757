import { ReportSummaryItemStoryblok } from '@maivenenergy/storyblok';

import IncentiveRow from '@/components/IncentiveRow';

interface ReportSummaryItemProps {
  blok: ReportSummaryItemStoryblok;
}

const ReportSummaryItem = ({ blok }: ReportSummaryItemProps) => {
  return (
    <IncentiveRow
      amount={Number(blok.value) || 0}
      incentiveType={blok.title || ''}
      incentiveQualifier={blok.description || ''}
    />
  );
};

export default ReportSummaryItem;
