import PlusIcon from '@heroicons/react/24/solid/PlusIcon';

import MaivenLogo from '@/assets/maiven.svg';
import PGELogo from '@/assets/pg&e-logo.svg';
import Text from '@/components/Typography';

const CompanyLogoFooter = () => {
  return (
    <div className='mt-auto flex flex-col items-center bg-transparent pb-2'>
      <div className='flex flex-row items-center'>
        <img src={PGELogo} alt='pg&e-logo' className='w-12 p-2' />
        <PlusIcon className='size-5' />
        <img src={MaivenLogo} alt='maiven-logo' className='w-12 p-2' />
      </div>
      <Text className='text-center text-sm text-gray-900'>
        ©2025 Pacific Gas and Electric Company.
        <br />
        All rights reserved
      </Text>
    </div>
  );
};

export default CompanyLogoFooter;
