import { ProgramHighlightItemStoryblok } from '@maivenenergy/storyblok';

import Text from '@/components/Typography';

interface ProgramHighlightItemProps {
  blok: ProgramHighlightItemStoryblok;
}

const ProgramHighlightItem = ({ blok }: ProgramHighlightItemProps) => {
  return (
    <div className='relative box-border py-[15px]'>
      <div className='absolute inset-x-1 top-0 h-px bg-gray-600' />
      <Text className='text-xs'>{blok.text}</Text>
    </div>
  );
};

export default ProgramHighlightItem;
