import React from 'react';

import { ButtonProps } from '@/components/Button';
import Modal from '@/components/Modal';
import Text from '@/components/Typography';
import { useAuthContext } from '@/contexts/AuthContext';

interface Props {
  triggerButtonProps?: ButtonProps;
  alternateMessage?: JSX.Element;
}

const AssessmentLeaveModal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  triggerButtonProps,
  alternateMessage,
}) => {
  const { handleLogout } = useAuthContext();

  return (
    <Modal
      action='warning'
      title='Leave the app?'
      primaryActionLabel='Sign out'
      secondaryActionLabel='Cancel'
      primaryActionProps={{
        onClick: handleLogout,
      }}
      triggerButtonContent={<>{children}</>}
      triggerButtonProps={triggerButtonProps}
    >
      <Text>
        {alternateMessage ? (
          <>{alternateMessage}</>
        ) : (
          <>We&apos;ve saved your progress for when you&apos;re ready to finish your assessment.</>
        )}
      </Text>
    </Modal>
  );
};

export default AssessmentLeaveModal;
