import { StoryblokRichTextNode } from '@storyblok/react';
import { useRef } from 'react';

import { useEnrollment } from '.';

import { useGetEnrollmentReviewStory } from '@/api';
import Text from '@/components/Typography';
import { logger } from '@/lib/Logger';
import { render } from '@/storyblokComponents/RichtextDisplay';

const EnrollmentReview = () => {
  const { programName } = useEnrollment();
  const documentContainerRef = useRef<HTMLDivElement>(null);
  const { data, isLoading, error } = useGetEnrollmentReviewStory();

  const blok = data?.story?.content;
  const errorState = !data || !blok || error;

  const document = blok?.document?.[0]?.content?.document;
  const textHtml = blok?.document ? (render(document as StoryblokRichTextNode) as string) : '';

  if (isLoading) {
    return null;
  }

  if (errorState) {
    logger.error('Failed to fetch enrollment review story', { error });
    return <Text>Something wrong happened. Try again</Text>;
  }
  return (
    <div className='flex flex-col gap-[10px] p-8'>
      <Text className='text-lg font-bold'>{programName}</Text>
      <Text className='text-2xl font-bold'>{blok.title}</Text>
      <div className='mt-3 w-full rounded bg-gray-100 p-4' ref={documentContainerRef}>
        <span
          className='wysiwyg'
          dangerouslySetInnerHTML={{
            __html: textHtml,
          }}
        />
      </div>
    </div>
  );
};

export default EnrollmentReview;
