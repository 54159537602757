import UserProfileContext, { UserProfileContextValue } from './context';

import { useGetUserProfile } from '@/api';
import Text from '@/components/Typography';
import { logger } from '@/lib/Logger';

const UserProfileProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data, isLoading, error } = useGetUserProfile();

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const errorState = !data || error;

  if (errorState) {
    logger.error('Failed to fetch user profile', { error });

    return <Text>Something wrong happened. Try again</Text>;
  }

  const contextValue: UserProfileContextValue = {
    profile: data,
  };
  return <UserProfileContext.Provider value={contextValue}>{children}</UserProfileContext.Provider>;
};

export default UserProfileProvider;
