import { ProgramSummaryBlockStoryblok } from '@maivenenergy/storyblok';
// TODO (LUKE): Leaving commented blocks in case we need to get them back in a hurry
// import { StoryblokComponent } from '@storyblok/react';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import Text from '@/components/Typography';

interface ProgramSummaryBlockProps {
  blok: ProgramSummaryBlockStoryblok;
}

const ProgramSummaryBlock = ({ blok }: ProgramSummaryBlockProps) => {
  const navigate = useNavigate();

  return (
    <div className='mb-4 flex flex-col gap-[10px] bg-transparent p-4'>
      <div className='rounded-xl bg-gradient-to-bl from-[#FDBA25] via-[#F49E87] to-[#9747FF] p-px'>
        <div className='flex flex-col items-center justify-between rounded-xl bg-white p-4 shadow-md'>
          <img src={blok?.image?.filename || ''} alt='program-summary' className='mb-4 max-h-[180px]' />
          <Text className='px-4 text-2xl font-bold'>{blok.title}</Text>
          <Text className='px-4'>{blok.description}</Text>
          {/* {blok.highlights?.map((highlight) => {
            return <StoryblokComponent blok={highlight} key={highlight._uid} />;
          })} */}
          {blok.button_text && (
            <Button
              className='mx-auto mt-4 w-full max-w-[95%]'
              onClick={() => {
                navigate('/dashboard/opportunities-details', {
                  state: {
                    detailsSlug: blok.button_link?.cached_url,
                    recommendationStrategy: blok.recommendation_strategy,
                  },
                });
              }}
              buttonStyle='dark'
            >
              {blok.button_text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgramSummaryBlock;
