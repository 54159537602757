import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import {
  AssessmentResult,
  usePatchUser,
  usePatchUserProperty,
  UserPropertyOwnershipType,
  useValidateAddress,
  usePatchServiceAccount,
  AssessmentDetails,
} from '@/api';
import { useGetUserUploadsSignedUrls } from '@/api/hooks/mutations/useGetUserUploadsSignedUrls';
import AddressSuggestion from '@/components/AddressSuggestion';
import Input from '@/components/Input';
import PictureInput from '@/components/PictureInput';
import Select, { SelectItem } from '@/components/Select';
import { useDebounce } from '@/hooks/useDebounce';
import { uploadFileToUrl } from '@/lib';

interface Props {
  userId: string;
  propertyId: string;
  coreField: string;
  affiliateId: string;
  result?: AssessmentResult;
  results: Record<string, AssessmentResult>;
}

const formLabels: Record<string, string | undefined> = {
  first_name: 'First Name',
  last_name: 'Last Name',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  phone: 'Enter your phone number',
  'own/rent': 'What describes you?',
  profile_pic: 'Profile Pic (optional)',
  preferred_language: 'What is your preferred language?',
  residents_count: 'Number of residents (including yourself)',
  report_on_id: 'PG&E Electric Service Account ID',
  household_income: 'Enter your total household income',
};

// TODO (Aimee): We'll need to source these options from storyblok for them to be translated
const ownRentItems: SelectItem[] = [
  { label: 'Own', value: 'own' },
  { label: 'Rent', value: 'rent' },
  { label: 'Landlord', value: 'landlord' },
];

const preferredLanguageItems: SelectItem[] = [
  { label: 'English', value: 'english' },
  { label: 'Spanish', value: 'spanish' },
  { label: '简体中文', value: 'simplified_chinese' },
  { label: '繁体中文', value: 'traditional_chinese' },
];

const AssessmentFormCoreField: React.FC<Props> = ({ coreField, propertyId, userId, affiliateId, result, results }) => {
  const queryClient = useQueryClient();
  const formSchema = useMemo(
    () =>
      yup.object({
        [coreField]: yup.string().required('This field is required'),
      }),
    [coreField]
  );

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<yup.InferType<typeof formSchema>>({
    resolver: yupResolver(formSchema),
  });

  const { mutateAsync: patchUser, isPending: isLoadingPatchUser } = usePatchUser();
  const { mutateAsync: patchUserProperty, isPending: isLoadingPatchUserProperty } = usePatchUserProperty();
  const { mutateAsync: getUserUploadsSignedUrls, isPending: isLoadingUserUploadsSignedUrls } =
    useGetUserUploadsSignedUrls();
  const { mutateAsync: patchServiceAccount, isPending: isLoadingPatchServiceAccount } = usePatchServiceAccount();
  const { mutateAsync: validateAddress } = useValidateAddress();

  const debouncedPatchUser = useDebounce(async (data: Record<string, string>) => {
    if (data.first_name || data.last_name || data.preferred_language || data.household_income || data.phone) {
      await patchUser({
        coreField,
        userId,
        firstName: data.first_name,
        lastName: data.last_name,
        preferredLanguage: data.preferred_language,
        householdIncome: data.household_income,
        phone: data.phone,
      });
    }
  }, 200);

  const debouncedPatchUserProperty = useDebounce(async (data: Record<string, string>) => {
    if (data.residents_count || data['own/rent']) {
      await patchUserProperty({
        coreField,
        userId,
        propertyId,
        residentsCount: data.residents_count,
        ownershipType:
          data['own/rent'] === 'own'
            ? UserPropertyOwnershipType.OWN
            : data['own/rent'] === 'landlord'
              ? UserPropertyOwnershipType.LANDLORD
              : data['own/rent'] === 'rent'
                ? UserPropertyOwnershipType.RENT
                : undefined,
      });
    }
  }, 200);

  const debouncedPatchServiceAccount = useDebounce(async (data: Record<string, string>) => {
    if (data.report_on_id) {
      await patchServiceAccount({
        coreField,
        reportOnId: data.report_on_id,
      });
    }
  }, 200);

  const debouncedPatchAddressFields = useDebounce(
    async ({ street, city, state, zip }: { street: string; city: string; state: string; zip: string }) => {
      // Make a single API call with all address fields
      await patchUserProperty({
        coreField: 'address',
        userId,
        propertyId,
        address1: street,
        city,
        state,
        zip,
      });
    },
    200
  );

  const onSubmit = (data: Record<string, string>) => {
    debouncedPatchUser(data);
    debouncedPatchUserProperty(data);
    debouncedPatchServiceAccount(data);
  };

  return (
    <form
      id={`${coreField}-form`}
      onBlur={handleSubmit(onSubmit)}
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col gap-3'
    >
      <Controller
        key={coreField}
        name={coreField}
        defaultValue={result?.value ?? ''}
        control={control}
        render={({ field }) => {
          const label = formLabels[field.name];
          const fieldProps = {
            'data-id': field.name,
            label,
            ...field,
            errors,
            disabled:
              !!field.disabled || isLoadingPatchUser || isLoadingPatchUserProperty || isLoadingPatchServiceAccount,
          };

          switch (field.name) {
            case 'address':
              return (
                <AddressSuggestion
                  defaultValue={
                    [results.address?.value, results.city?.value, results.state?.value, results.zip?.value]
                      .filter((value) => value?.trim?.())
                      .join(', ') || undefined
                  }
                  isLoading={isLoadingPatchUserProperty}
                  data-id={fieldProps.name}
                  label='Address'
                  errors={errors}
                  placeholder='Search for an address...'
                  onPlaceChanged={async (data) => {
                    const fullAddress = [data.street, data.city, data.state, data.zip].join(', ');

                    const validation = await validateAddress({
                      address: fullAddress,
                      affiliateId,
                    });

                    // Update the validation state in the results object
                    queryClient.setQueryData(['assessment-details'], (old?: AssessmentDetails) => ({
                      ...old,
                      results: {
                        ...old?.results,
                        address_validation: {
                          id: 'address-validation',
                          value: validation.isValid.toString(),
                          type: 'USER_PROVIDED',
                          values: [],
                        },
                      },
                    }));

                    debouncedPatchAddressFields({
                      street: data.street,
                      city: data.city,
                      state: data.state,
                      zip: data.zip,
                    });

                    if (!validation.isValid) {
                      setError('address', {
                        type: 'manual',
                        message: 'This address is not in a valid service zone',
                      });
                      return;
                    }
                  }}
                />
              );
            case 'first_name':
            case 'last_name':
            case 'residents_count':
            case 'report_on_id':
            case 'household_income':
              return <Input autoComplete='off' {...fieldProps} type='text' />;
            case 'preferred_language':
              return <Select items={preferredLanguageItems} placeholder='Choose an option...' {...fieldProps} />;
            case 'own/rent':
              return <Select items={ownRentItems} placeholder='Choose an option...' {...fieldProps} />;
            case 'phone':
              return <Input autoComplete='off' {...fieldProps} type='tel' />;
            case 'city':
            case 'state':
            case 'zip':
            case 'profile_pic':
              return <></>;
            default:
              return <div>TODO: Handle core_field type = {field.name}</div>; // TODO update this - now that we can add questions in production via storyblok, we need to handle this differently
          }
        }}
      />
      {coreField === 'profile_pic' && (
        <PictureInput
          data-id={coreField}
          value={result?.value}
          label={formLabels[coreField]}
          disabled={isLoadingUserUploadsSignedUrls}
          accept='image/*'
          onChange={async (e) => {
            const file = e.target.files?.[0];

            if (!file) {
              return;
            }

            const fileExtension = file.name.split('.').pop();
            const filename = `${coreField}.${fileExtension}`;

            const response = await getUserUploadsSignedUrls({
              userId,
              files: [{ filename, contentType: file.type }],
            });

            const uploadFile = response.files[0];

            if (!uploadFile?.url) {
              return;
            }

            await uploadFileToUrl(file, uploadFile.url);

            await patchUser({
              coreField,
              userId,
              avatarPath: uploadFile.filename,
              overrideNewValue: uploadFile.getUrl,
            });
          }}
        />
      )}
    </form>
  );
};

export default AssessmentFormCoreField;
