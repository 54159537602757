import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { AssessmentDetails, AssessmentResultType, UserPropertyOwnershipType } from '@/api/types';

interface PatchUserPropertyRequestParams {
  coreField: string;
  userId: string;
  propertyId: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  ownershipType?: UserPropertyOwnershipType;
  residentsCount?: string;
}

export const usePatchUserProperty = () => {
  const { getClient } = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestParams: PatchUserPropertyRequestParams) => {
      const client = await getClient();
      await client.patch(`/users/${requestParams.userId}/properties/${requestParams.propertyId}`, {
        address1: requestParams.address1,
        address2: requestParams.address2,
        city: requestParams.city,
        state: requestParams.state,
        zip: requestParams.zip,
        ownershipType: requestParams.ownershipType,
        residentsCount: requestParams.residentsCount,
      });
    },

    onSuccess: (_, variables) => {
      queryClient.setQueryData(['assessment-details'], (old?: AssessmentDetails): AssessmentDetails | undefined => {
        return old
          ? {
              ...old,
              results: {
                ...old.results,
                [variables.coreField]: {
                  id: old.results[variables.coreField]?.id ?? `new-response-${Date.now()}`,
                  value:
                    variables.address1 ??
                    variables.address2 ??
                    variables.city ??
                    variables.state ??
                    variables.zip ??
                    variables.ownershipType ??
                    variables.residentsCount ??
                    '',
                  assessmentQuestionId: variables.coreField,
                  type: AssessmentResultType.USER_PROVIDED,
                  values: [],
                },
              },
            }
          : old;
      });
    },
  });
};
