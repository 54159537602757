import React, { FC } from 'react';

import SVGIcon, { SVGIconProps } from '@/components/SVGIcon';

const MaivenIconLight: FC<SVGIconProps> = ({ width = '48', height = '48', viewBox = '0 0 48 48' }) => {
  return (
    <SVGIcon width={width} height={height} viewBox={viewBox}>
      <path
        d='M66.8412 30C62.6306 30 58.85 32.009 55.6055 35.9724C53.0285 39.1198 50.9498 43.3014 49.43 46.3546L49.1341 46.9499C47.4777 50.2858 46.0464 53.1653 44.486 55.2735C43.2115 56.9948 42.1367 57.8331 41.201 57.8331C40.9229 57.8331 40.7838 57.7984 40.7357 57.7835C40.6801 57.7215 40.407 57.3718 40.1769 56.2978C39.7899 54.4922 39.8127 52.2749 39.8355 49.688C39.843 48.9613 39.8506 48.2049 39.8481 47.4186H39.6079L39.8481 47.4112C39.8481 43.6809 39.6129 39.4149 37.8832 36.0716C36.8615 34.0998 35.4049 32.577 33.5538 31.5452C31.7153 30.5233 29.4975 30.005 26.9559 30.005C24.068 30.005 21.2432 31.2054 18.3224 33.6732C16.1299 35.5259 13.8261 38.1525 11.277 41.7042C8.63684 45.3824 5.89555 49.8195 3 54.7998V67H6.20913L6.27741 66.876C10.3691 59.5568 14.6834 52.0467 18.5196 46.7018C20.6135 43.7826 22.4925 41.6124 24.1034 40.2508C25.818 38.8023 26.7208 38.6882 26.9559 38.6882C28.19 38.6882 28.8475 38.9115 29.1839 39.0975C29.4039 39.219 29.6922 39.4323 29.9855 40.0003C30.6708 41.3247 30.992 43.6834 30.992 47.4261C30.992 47.937 30.987 48.5025 30.9794 49.1126C30.9465 51.8483 30.9035 55.2537 31.5104 58.0786C32.0238 60.472 32.9342 62.3248 34.2947 63.741C36.0371 65.5565 38.4269 66.5164 41.201 66.5164C45.1714 66.5164 48.5904 64.5074 51.6503 60.3778C53.6911 57.6223 55.4461 54.0805 56.9963 50.9554L57.3908 50.1618C59.0574 46.8085 60.7037 43.6189 62.5093 41.4115C64.0519 39.5265 65.3896 38.6857 66.8412 38.6857C67.739 38.6857 68.0854 38.9338 68.1992 39.0131C68.5254 39.2463 68.8289 39.6679 69.0995 40.2657C69.7924 41.8009 70.1439 44.2067 70.1439 47.4137V66.995H79V47.4137C79 42.9245 78.4108 39.4348 77.1969 36.7462C76.284 34.7248 75.0044 33.1201 73.391 31.9767C71.5424 30.6647 69.3372 30 66.8387 30H66.8412Z'
        fill='#030712'
      />
    </SVGIcon>
  );
};

export default MaivenIconLight;
