import { RichtextStoryblok, MultiassetStoryblok } from '@maivenenergy/storyblok';
import { StoryblokRichTextNode } from '@storyblok/react';
import { Dispatch, SetStateAction } from 'react';

import ModalDisplay from '@/components/ModalDisplay';
import Text from '@/components/Typography';
import { render } from '@/storyblokComponents/RichtextDisplay';

interface AssessmentFormQuestionInfoProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  title?: string;
  subtitle?: string;
  document?: RichtextStoryblok;
  images?: MultiassetStoryblok;
}

const AssessmentFormQuestionInfo = ({
  showModal,
  setShowModal,
  title,
  subtitle,
  document,
  images,
}: AssessmentFormQuestionInfoProps) => {
  const closeModalHandle = () => {
    setShowModal(false);
  };
  const textHtml = document ? (render(document as StoryblokRichTextNode) as string) : '';

  return (
    <ModalDisplay showModal={showModal} closeModalHandle={closeModalHandle}>
      <div className='flex flex-col px-4 py-2'>
        {title ? <Text className='text-2xl font-bold'>{title}</Text> : null}
        {subtitle ? <Text className='mt-2 text-base'>{subtitle}</Text> : null}

        {textHtml.length ? (
          <span
            className='mt-4'
            dangerouslySetInnerHTML={{
              __html: textHtml,
            }}
          />
        ) : null}

        {images?.length ? (
          <div className='mt-4'>
            {images?.map((image, idx) => {
              return image?.filename ? (
                <img key={'image' + idx} src={image.filename} alt={image.alt ?? title} className='mt-2' />
              ) : null;
            })}
          </div>
        ) : null}
      </div>
    </ModalDisplay>
  );
};

export default AssessmentFormQuestionInfo;
