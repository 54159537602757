import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';

type Response = {
  success: boolean;
  message: string;
};

export type UseRequestAsyncUsageDataParams =
  | {
      enabled: false;
    }
  | {
      enabled: true;
      startDate: string;
      endDate: string;
    };

export const useRequestAsyncUsageData = (options: UseRequestAsyncUsageDataParams) => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: ['request-async-usage-data'] as const,
    queryFn: async () => {
      const client = await getClient();
      const response = await client.get<Response>(`/green-button/usage/pge/async`, {
        params: {
          startDate: options.enabled ? options.startDate : undefined,
          endDate: options.enabled ? options.endDate : undefined,
        },
      });

      return response.data;
    },
    enabled: options.enabled,
  });
};
