import { InformationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction } from 'react';

import Text from '@/components/Typography';

interface FormFieldLabelProps {
  ['data-id']: string;
  label?: string;
  htmlFor?: string;
  className?: string;
  hasInfo?: boolean;
  onClickInfo?: Dispatch<SetStateAction<boolean>>;
}

const FormFieldLabel: React.FC<FormFieldLabelProps> = ({
  label,
  'data-id': dataId,
  htmlFor,
  className,
  hasInfo,
  onClickInfo,
}) => {
  if (!label) {
    return null;
  }

  return (
    <Text className={clsx(className, 'mt-2 flex')} id={`${dataId}-label`} htmlFor={htmlFor} as='label' size='sm'>
      <span>{label}</span>
      {hasInfo && onClickInfo ? (
        <span className='mx-1'>
          <button onClick={() => onClickInfo(true)}>
            <InformationCircleIcon className='size-5 text-black' />
          </button>
        </span>
      ) : null}
    </Text>
  );
};

export default FormFieldLabel;
