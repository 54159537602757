import { ChevronRightIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CollapsibleCard from '@/components/CollapsibleCard';
import Text from '@/components/Typography';
// import AssessmentFormNavigationIcon from '@/pages/Assessment/components/AssessmentForm/AssessmentFormNavigationIcon';
import {
  AssessmentFormSection,
  AssessmentFormSectionContent,
} from '@/pages/Assessment/components/AssessmentForm/hooks/types';

interface Props {
  sectionNames: AssessmentFormSection[];
  contents: Record<string, AssessmentFormSectionContent>;
}

const AssessmentReviewAndSubmit: React.FC<Props> = ({ contents, sectionNames: _sectionNames }) => {
  const [, setSearchParams] = useSearchParams();
  const sectionNames = _sectionNames.filter((section) => section.id !== 'summary');

  return (
    <div className='mt-6 flex flex-col gap-2 rounded-2xl bg-gray-50 p-4 sm:mb-5 sm:self-center'>
      <Text className='font-bold'>Assessment Recap</Text>
      {sectionNames.map((section) => {
        return (
          <CollapsibleCard
            key={section.id}
            heading={
              <div className='flex grow flex-col justify-between gap-1'>
                <div className='flex items-center gap-2'>
                  {/* <AssessmentFormNavigationIcon status={section.status} /> */}
                  <Text as='label' className='grow font-bold'>
                    {section.id}
                  </Text>
                </div>
                {/* <Text size='xs' className='grow pl-7'>
                  {!section.answered
                    ? 'All items missing'
                    : missingItems > 0
                      ? `${missingItems} missing item${missingItems > 1 ? 's' : ''}`
                      : 'Complete'}
                </Text> */}
              </div>
            }
          >
            {contents[section.id].subsections.map((subsection, subSectionIdx) => {
              return (
                <div
                  key={subsection.section_order + subsection.header}
                  className='flex grow cursor-pointer gap-2 text-gray-900 hover:text-gray-400'
                  onClick={() => {
                    setSearchParams({
                      currentSection: section.id,
                      currentSubsection: subSectionIdx + subsection.header + (subsection.repeats_on?.length ? '0' : ''),
                    });
                  }}
                >
                  <Text
                    size='sm'
                    className={clsx('grow font-bold', {
                      // 'text-orange-500': section.notAnsweredSectionsNames.includes(subsection.header),
                    })}
                  >
                    {subsection.header}
                  </Text>
                  <span className='size-5'>
                    <ChevronRightIcon />
                  </span>
                </div>
              );
            })}
          </CollapsibleCard>
        );
      })}
    </div>
  );
};

export default AssessmentReviewAndSubmit;
