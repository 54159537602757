import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AssessmentForm from './components/AssessmentForm';
import { OAUTH_REDIRECT_PARAM } from './components/AssessmentForm/ShareUsageData';
import AssessmentIntroduction from './components/AssessmentIntroduction';

import { AssessmentStatus } from '@/api';
import { useRequestAsyncUsageData } from '@/api/hooks/queries/useRequestAsyncUsageData';
import { useAssessmentContext } from '@/contexts/AssessmentContext';

const Assessment = () => {
  const {
    details: { template, assessmentStatus },
    termsOfService,
  } = useAssessmentContext();
  // 18 months ago
  const startDate = new Date(Date.now() - 18 * 30 * 24 * 60 * 60 * 1000).toISOString();
  // Now
  const endDate = new Date().toISOString();

  const navigate = useNavigate();

  const [started, setStarted] = useState(!template.content.display_carousel);
  const [shouldFetchUsageData, setShouldFetchUsageData] = useState(false);

  const { isSuccess } = useRequestAsyncUsageData({
    startDate,
    endDate,
    enabled: shouldFetchUsageData,
  });

  const [searchParams] = useSearchParams();

  // TODO (Luke): We should probably memoize this to prevent unnecessary re-renders
  useEffect(() => {
    const currentSectionSearchParam = searchParams.get('currentSection');
    const oAuthRedirectParam = searchParams.get(OAUTH_REDIRECT_PARAM);

    if (currentSectionSearchParam && oAuthRedirectParam === 'true') {
      // Trigger the usage data request
      setShouldFetchUsageData(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (isSuccess) {
      // When usage data request is successful, set started to true
      setStarted(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!termsOfService?.acceptedAt) {
      navigate('/terms');
    }
  }, [navigate, termsOfService?.acceptedAt]);

  useEffect(() => {
    if (assessmentStatus === AssessmentStatus.COMPLETE) {
      navigate('/assessment-complete');
    }
  }, [navigate, assessmentStatus]);

  if (started) return <AssessmentForm />;

  return <AssessmentIntroduction onBegin={() => setStarted(true)} />;
};

export default Assessment;
