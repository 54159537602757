import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { EnrollmentSuccessStory } from '@/api/types';

export const useGetEnrollmentSuccessStory = () => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: ['enrollment-sign-story'] as const,
    queryFn: async () => {
      const client = await getClient();

      const response = await client.get<EnrollmentSuccessStory>('/stories/story/zeep-enrollment-success-page');

      return response.data;
    },
  });
};
