import { useLocation } from 'react-router-dom';

import BottomBar from './BottomBar';
import TopBar from './TopBar';

interface ApplicationLayoutProps {
  children: React.ReactNode;
}

const ApplicationLayout = ({ children }: ApplicationLayoutProps) => {
  const location = useLocation();

  return (
    <div className='mx-auto flex min-h-screen w-full flex-col pb-16 md:max-w-2xl'>
      <TopBar showMaivenLogo showBackButton={location.pathname === '/dashboard/opportunities-details'} />
      {children}
      <BottomBar />
    </div>
  );
};

export default ApplicationLayout;
