import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { AssessmentDetails } from '@/api/types';

interface DeleteAssessmentQuestionResultValueRequestParams {
  assessmentId: string;
  questionId: string;
  resultId: string;
  resultValueId: string;
}

export const useDeleteAssessmentQuestionResultValue = () => {
  const { getClient } = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestParams: DeleteAssessmentQuestionResultValueRequestParams) => {
      const client = await getClient();

      await client.delete(
        `/assessments/${requestParams.assessmentId}/questions/${requestParams.questionId}/results/${requestParams.resultId}/values/${requestParams.resultValueId}`
      );
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(['assessment-details'], (old?: AssessmentDetails): AssessmentDetails | undefined => {
        return old
          ? {
              ...old,
              results: {
                ...old.results,
                [variables.questionId]: {
                  ...old.results[variables.questionId],
                  values: old.results[variables.questionId].values.filter(
                    (value) => value.id !== variables.resultValueId
                  ),
                },
              },
            }
          : old;
      });
    },
  });
};
