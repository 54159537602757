import MaivenAvatarGradientDark from './MaivenAvatarGradientDark';
import MaivenAvatarGradientLight from './MaivenAvatarGradientLight';
import MaivenIconDark from './MaivenIconDark';
import MaivenIconGradient from './MaivenIconGradient';
import MaivenIconLight from './MaivenIconLight';
import MaivenWordmark from './MaivenWorkmark';

export default {
  MaivenAvatarGradientDark,
  MaivenAvatarGradientLight,
  MaivenIconDark,
  MaivenIconGradient,
  MaivenIconLight,
  MaivenWordmark,
};
