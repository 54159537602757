import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { ScopeOfWork, RecommendationStrategy } from '@/api/types';

export const useGetScopeOfWork = (recommendationStrategy: RecommendationStrategy) => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: ['get-scope-of-work'] as const,
    queryFn: async () => {
      const client = await getClient();

      const response = await client.get<ScopeOfWork>(`/scope-of-work?recommendationStrategy=${recommendationStrategy}`);

      return response.data;
    },
  });
};
