import DomainIcon from '@/assets/domain-icon.svg';
import EmailIcon from '@/assets/email-icon.svg';
import MaivenTextImg from '@/assets/maiven-text.svg';
import Text from '@/components/Typography';

const MaivenInfo = () => {
  return (
    <div className='flex flex-col gap-4 border-t-2 pt-4'>
      <img src={MaivenTextImg} alt={'maiven text'} className='w-28' />
      <div>
        <Text className='text-sm'>Maiven Energy, Inc</Text>
        <Text className='text-sm'>2101 Pearl St. Boulder, Colorado 80302</Text>
      </div>
      <div>
        <div className='flex flex-row gap-3 p-2'>
          <img src={DomainIcon} alt={'domain icon'} className='w-6' />
          <Text className='font-bold'>askmaiven.com</Text>
        </div>
        <div className='flex flex-row gap-3 p-2'>
          <img src={EmailIcon} alt={'email icon'} className='w-6' />
          <Text className='font-bold'>hello@askmaiven.com</Text>
        </div>
      </div>
      <div className='flex flex-col gap-3 py-2'>
        <Text className='text-xs'>Maiven is a Public Benefit Corporation</Text>
        <Text className='text-xs'>
          <a href='https://www.askmaiven.com/privacy-policy'>Privacy Policy</a>
        </Text>
      </div>
    </div>
  );
};

export default MaivenInfo;
