import React, { useMemo } from 'react';

import { LookupQuestion } from './types';

import { AssessmentResult, useGetAssessmentQuestionLookupDetails } from '@/api';
import Select, { SelectItem } from '@/components/Select';

interface Props {
  assessmentId: string;
  questionId: string;
  question: LookupQuestion;
  result?: AssessmentResult;
  dependingQuestions: string[];
  onChange: (value: string) => void;
  resultIndex?: number;
  filterOnQuestion?: LookupQuestion;
  filterOnResult?: AssessmentResult;
}

const getLookupSearchParams = (question: LookupQuestion, filterOnResult?: AssessmentResult) => {
  const params: {
    [key: string]: {
      component?: string;
      lookupTable?: string;
      lookupValue?: string;
    };
  } = {
    make: {
      lookupTable: question.lookup_table,
      component: 'LookupMake',
    },
    model: {
      lookupValue: filterOnResult?.values?.[0]?.value,
      component: 'LookupModel',
    },
    capacity: {
      lookupValue: filterOnResult?.values?.[0].value,
      component: 'LookupCapacityKwh',
    },
    default: {},
  };
  return params[question.lookup_column ?? 'default'];
};

const AssessmentFormSingleSelectLookup: React.FC<Props> = ({
  assessmentId,
  question,
  questionId,
  onChange,
  result,
  resultIndex,
  filterOnQuestion,
  filterOnResult,
}) => {
  const resultValue = result?.values.find((r) => r.index === resultIndex);

  const params = getLookupSearchParams(question, filterOnResult);
  const { data } = useGetAssessmentQuestionLookupDetails({
    assessmentId,
    questionId,
    ...params,
  });

  const name = question.name + resultIndex;

  const fieldProps = {
    'data-id': name,
    name,
    label: question.display,
    value: resultValue?.value ?? '',
    disabled: false,
    onChange,
  };

  const items = useMemo(() => {
    const _data = data?.data ?? [];
    const set = new Set(_data);
    const arr = Array.from(set);

    return arr.sort();
  }, [data]);

  return (
    <div>
      <Select
        items={items.map<SelectItem>((option) => ({
          label: option,
          value: option,
        }))}
        placeholder='Choose an option...'
        {...fieldProps}
      />
    </div>
  );
};

export default AssessmentFormSingleSelectLookup;
