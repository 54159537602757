import { createContext } from 'react';

import { ProfileType } from '@/api';

export interface UserProfileContextValue {
  profile: ProfileType;
}

const UserProfileContext = createContext<UserProfileContextValue>({} as UserProfileContextValue);

export default UserProfileContext;
