import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AssessmentStatus, useGetAssessmentRunStatus } from '@/api';
import { AssessmentRunStatus } from '@/api/types';
import ReportLoadingImage from '@/assets/report-loading.gif';
import Button from '@/components/Button';
import Text from '@/components/Typography';
import { useAssessmentContext } from '@/contexts/AssessmentContext';
import { useUserProfileContext } from '@/contexts/UserProfileContext';

const AssessmentComplete: React.FC = () => {
  const {
    details: { assessmentId, assessmentStatus },
  } = useAssessmentContext();
  const navigate = useNavigate();
  const { profile } = useUserProfileContext();
  const { data, isFetching } = useGetAssessmentRunStatus(assessmentId);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (assessmentStatus !== AssessmentStatus.COMPLETE) {
      navigate('/terms');
    }
  }, [navigate, assessmentStatus]);

  useEffect(() => {
    if (isFetching) return;
    if (data?.status === AssessmentRunStatus.FINISHED || data?.timedOut) {
      setProgress(100);
      setButtonDisabled(false);
    }
  }, [data, isFetching]);

  useEffect(() => {
    setProgress((prevProgress) => Math.min(prevProgress + 1, 100));
  }, [isFetching]);

  return (
    <div className='absolute flex size-full justify-center bg-gradient-to-b from-[#A546E8] via-[#FFFFFF] to-[#FFFFFF] text-black'>
      <div className='flex h-full flex-col justify-between gap-3 py-6 text-center md:w-1/2 md:justify-center md:p-0'>
        <div className='flex flex-col gap-2 px-10'>
          <div
            className='relative mx-auto mt-4 size-[17rem] rounded-xl bg-cover bg-center p-4'
            style={{ backgroundImage: `url('${ReportLoadingImage}')` }}
          />
          <div className='mx-auto mt-6 h-1.5 w-48 overflow-hidden rounded-full bg-gray-200'>
            <div
              className='h-full bg-gradient-to-r from-[#6100EE] to-[#D56AFF] transition-all duration-300 ease-in-out'
              style={{ width: `${progress}%` }} // where progress is 0-100
            />
          </div>
          <Text size='xs' className='text-gray-500'>
            {buttonDisabled ? 'generating report ...' : 'report generated!'}
          </Text>
          <Text as='h1' size='3xl' className='mt-8 font-bold'>
            {`Amazing work, ${profile?.first_name}!`}
          </Text>
          <Text>
            Kick back and relax while our energy-obsessed robots match you with the best incentives to save and
            decarbonize!
          </Text>
          {/* <Text className='font-bold'>Estimated Time: 3 minutes</Text> */}
          <div className='mt-4 flex flex-col place-content-center gap-2'>
            <Button
              className='mx-auto'
              buttonType={buttonDisabled ? 'outline' : 'normal'}
              buttonStyle='dark'
              size='base'
              onClick={() => {
                navigate('/dashboard/opportunities');
              }}
              disabled={buttonDisabled}
            >
              See Results
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentComplete;
