import { StoryblokAssessmentQuestion } from '@/api';

export type LookupQuestion = StoryblokAssessmentQuestion & {
  lookup_table: string;
  lookup_column: string;
};

export const isLookupQuestion = (question: StoryblokAssessmentQuestion): question is LookupQuestion => {
  return !!question.lookup_column && !!question.lookup_table;
};
