import { XMarkIcon } from '@heroicons/react/24/outline';

interface ModalDisplayProps {
  showModal: boolean;
  closeModalHandle: () => void;
}

const ModalDisplay: React.FC<React.PropsWithChildren<ModalDisplayProps>> = ({
  showModal,
  closeModalHandle,
  children,
}) => {
  return (
    showModal && (
      <div className='fixed inset-0 z-50 flex h-full cursor-default items-center justify-center overflow-scroll bg-white'>
        <div className='flex size-full flex-col rounded-lg'>
          <div className='flex justify-end p-4 pb-0'>
            <XMarkIcon
              className='w-5 cursor-pointer'
              onClick={(e) => {
                e.stopPropagation();
                closeModalHandle();
              }}
            />
          </div>
          {children}
        </div>
      </div>
    )
  );
};

export default ModalDisplay;
