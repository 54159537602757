import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';

import MaivenLogo from '@/assets/maiven.svg';

interface TopBarProps {
  showBackButton?: boolean;
  showCloseButton?: boolean;
  showMaivenLogo?: boolean;
}

const TopBar = ({ showBackButton = false, showCloseButton = false, showMaivenLogo = false }: TopBarProps) => {
  return (
    <div className='flex h-14 items-center bg-transparent px-4'>
      {showMaivenLogo && !showBackButton && <img src={MaivenLogo} alt='maiven-logo' className='w-8' />}
      {showBackButton && (
        <button className='p-2' onClick={() => window.history.back()}>
          <ArrowLeftIcon className='size-5' />
        </button>
      )}

      {!!showCloseButton && (
        <button className='p-2' onClick={() => window.close()}>
          <XMarkIcon className='size-5' />
        </button>
      )}
    </div>
  );
};

export default TopBar;
