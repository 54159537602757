import { Loader } from '@googlemaps/js-api-loader';
import React, { useState, useEffect, useRef } from 'react';
import { FieldErrors } from 'react-hook-form';

import Input, { InputProps } from '../Input';
import Text from '../Typography';

import { useGetStory } from '@/api/hooks/queries/useGetStory';
import { useAssessmentContext } from '@/contexts/AssessmentContext';
import AssessmentFormQuestionError from '@/pages/Assessment/components/AssessmentForm/AssessmentFormQuestionError';

interface AddressComponents {
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  version: 'weekly',
  libraries: ['places'],
});

const getAddressComponents = (components: google.maps.GeocoderAddressComponent[]): AddressComponents => {
  const componentMapping: { [key: string]: string } = {
    street_number: 'street',
    route: 'street',
    locality: 'city',
    administrative_area_level_1: 'state',
    postal_code: 'zip',
    country: 'country',
  };

  const result: Partial<AddressComponents> = {};

  components.forEach((component) => {
    const type = component.types[0];
    const field = componentMapping[type];

    if (field) {
      result[field as keyof AddressComponents] =
        field === 'street' ? `${result[field] ?? ''} ${component.long_name}`.trim() : component.long_name;
    }
  });

  return {
    street: result.street ?? '',
    city: result.city ?? '',
    state: result.state ?? '',
    zip: result.zip ?? '',
    country: result.country ?? '',
  };
};

interface Props extends InputProps {
  onPlaceChanged?: (address: AddressComponents) => void;
  defaultValue?: string;
  errors?: FieldErrors<{ [x: string]: string; [x: number]: string }>;
  isLoading: boolean;
}

const AddressSuggestion: React.FC<Props> = ({ onPlaceChanged, errors, name = 'address', isLoading, ...inputProps }) => {
  const {
    details: { affiliate },
  } = useAssessmentContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  // TODO (LUKE): This is a hard-coded way to approach this that Aimee has used before and flagged as
  // needing to be refactored. We should consider a better way to handle this via content-tag in the future.
  const programRejectionSlugs: {
    [programName: string]: string;
  } = {
    ZEEP: 'zeep-program-rejection-card',
    EPMH: 'epmh-program-rejection-card-location',
  };

  const { data: programRejectionStory, error: programRejectionStoryError } = useGetStory(
    programRejectionSlugs[affiliate.contentTag],
    true
  );

  useEffect(() => {
    let autocomplete: google.maps.places.Autocomplete | undefined = undefined;

    const initPlacesLibrary = async () => {
      loader.importLibrary('places').then((places) => {
        if (inputRef.current) {
          // Initialize the Autocomplete instance
          autocomplete = new places.Autocomplete(inputRef.current, {
            types: ['address'], // Restrict to addresses
            componentRestrictions: { country: 'us' }, // Restrict to a specific country
          });

          // Add listener for place selection
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete?.getPlace();
            if (place?.address_components && onPlaceChanged) {
              onPlaceChanged(getAddressComponents(place.address_components));
            }
          });
        }
      });
    };

    void initPlacesLibrary();
  }, [onPlaceChanged]);

  useEffect(() => {
    const error = errors?.[name];
    console.log('error', error);
    const hasError = Boolean(error);
    if (error?.type === 'required') {
      // due to timing differences in the component lifecycle this required error fires first and needs to be ignored for this component
      setErrorModalOpen(false);
      return;
    }
    setErrorModalOpen(hasError);
  }, [errors, name, isLoading]);

  if (programRejectionStoryError) {
    return <Text>Something wrong happened. Try again</Text>;
  }

  return (
    <div>
      <Input autoComplete='off' ref={inputRef} name={name} {...inputProps} />
      <AssessmentFormQuestionError
        showModal={errorModalOpen}
        setShowModal={setErrorModalOpen}
        title={programRejectionStory?.content?.title}
        text={programRejectionStory?.content?.text}
        graphic={programRejectionStory?.content?.graphic}
        programContactEmail={affiliate.programContactEmail}
        programContactPhone={affiliate.programContactPhone}
        programContactPhoneDisplay={affiliate.programContactPhoneDisplay}
      />
    </div>
  );
};

export default AddressSuggestion;
