import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { AssessmentDetails, AssessmentResultType, AssessmentResultValue } from '@/api/types';

interface PutAssessmentQuestionResultRequestParams {
  assessmentId: string;
  questionId: string;
  value: string;
  type: AssessmentResultType;
  userInput?: string;
  shouldRemoveUserInput?: boolean;
  overrideNewValue?: string;
  allowMultiple?: boolean;
  dependingQuestionIds: string[];
  resultIndex?: number;
}

interface PutAssessmentQuestionResultResponse {
  resultId: string;
  resultValueId?: string;
}

export const usePutAssessmentQuestionResult = () => {
  const { getClient } = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestParams: PutAssessmentQuestionResultRequestParams) => {
      const client = await getClient();

      const { data } = await client.put<PutAssessmentQuestionResultResponse>(
        `/assessments/${requestParams.assessmentId}/questions/${requestParams.questionId}/results`,
        {
          value: requestParams.value,
          type: requestParams.type,
          userInput: requestParams.userInput,
          shouldRemoveUserInput: requestParams.shouldRemoveUserInput,
          resultIndex: requestParams.resultIndex,
          dependingQuestionIds: requestParams.dependingQuestionIds,
        }
      );

      return data;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['assessment-details'], (old?: AssessmentDetails): AssessmentDetails | undefined => {
        if (old) {
          for (const dependingQuestionId of variables.dependingQuestionIds) {
            delete old.results[dependingQuestionId];
          }

          const getValues = () => {
            if (!variables.userInput) {
              return [];
            }

            const values: AssessmentResultValue[] = [];

            if (variables.allowMultiple) {
              const prev = old.results[variables.questionId]?.values ?? [];

              if (typeof variables.resultIndex === 'number') {
                values.push(...prev.filter((v) => v.index !== variables.resultIndex));
              } else {
                values.push(...prev);
              }
            }

            values.push({
              id: data.resultValueId ?? '',
              value: variables.overrideNewValue ?? variables.userInput,
              index: variables.resultIndex,
            });

            return values;
          };

          return {
            ...old,
            results: {
              ...old.results,
              [variables.questionId]: {
                id: data.resultId,
                value: variables.value,
                assessmentQuestionId: variables.questionId,
                type: variables.type,
                values: getValues(),
              },
            },
          };
        }

        return old;
      });
    },
  });
};
