import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React, { ForwardRefExoticComponent, RefAttributes, SVGProps, useState } from 'react';

import Text from '@/components/Typography';

type Icon = ForwardRefExoticComponent<
  Omit<SVGProps<SVGSVGElement>, 'ref'> & {
    title?: string;
    titleId?: string;
  } & RefAttributes<SVGSVGElement>
>;

interface CollapsibleListProps {
  Icon: Icon;
  status: string;
}

const CollapsibleList: React.FC<React.PropsWithChildren<CollapsibleListProps>> = ({ Icon, status, children }) => {
  const [show, setShow] = useState(false);

  const childrenCount = React.Children.count(children);

  return (
    <div className='flex flex-col gap-[10px] border-t border-gray-200 p-2'>
      <div className='flex items-center gap-2'>
        <Icon className='size-5' />
        <Text className='font-bold'>{status}</Text>
        <div className='ml-auto flex items-center'>
          <Text className='place-self-end font-bold'>{childrenCount}</Text>
          <span
            className='ml-1 size-5 cursor-pointer text-gray-800 hover:text-gray-400'
            onClick={() => setShow((show) => !show)}
          >
            {show && <ChevronUpIcon />}
            {!show && <ChevronDownIcon />}
          </span>
        </div>
      </div>
      <div className='flex flex-col gap-[10px]'>
        <Transition show={show}>
          <div className={clsx('flex flex-col gap-3 transition duration-300 ease-in-out data-[closed]:opacity-0')}>
            {children}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default CollapsibleList;
