import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@/api/hooks/useApiClient';
import { Story } from '@/api/types';

export const useGetStory = (slug: string, enabled: boolean) => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: ['get-story'] as const,
    queryFn: async () => {
      const client = await getClient();

      const response = await client.get<Story>(`/stories/story/${slug}`);

      return response.data;
    },
    enabled,
  });
};
