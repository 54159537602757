import { RichtextStoryblok } from '@maivenenergy/storyblok';
import { richTextResolver, StoryblokRichTextNode } from '@storyblok/richtext';
import './styles.css';

interface RichtextDisplayProps {
  richText: RichtextStoryblok;
  className?: string;
}

const getHeadingLevelClass = (nodeLevel: number) => {
  if (nodeLevel < 1 || nodeLevel > 6) {
    return '';
  }
  const levels: {
    [key: number]: string;
  } = {
    1: 'content-section font-bold text-2xl leading-normal',
    2: 'content-section font-bold text-xl leading-tight mt-3',
    3: 'content-section font-bold text-lg leading-[1.125] mt-4',
    4: 'content-section font-bold text-base leading-none mt-4',
    5: 'content-section font-bold text-sm leading-[.875] mt-4',
    6: 'content-section font-bold text-xs leading-[.75] mt-4',
  };

  return levels[nodeLevel];
};

export const { render } = richTextResolver({
  resolvers: {
    paragraph: (node) => {
      const children = node.children as string[];
      if (!children) return null;
      return `<p class="${node?.attrs?.tailwindStyle || ''}">${children.join()}</p>`;
    },
    heading: (node) => {
      const level = node.attrs?.level || 1;
      const children = node.children as string[];
      const className = getHeadingLevelClass(level);
      if (!children) return null;
      return `<h${level} class='${className}'>${children.join()}</h${level}>`;
    },
    list_item: (node) => {
      const children = node.children as string[];
      if (!children) return null;
      return `<li>${children.join()}</li>`;
    },
  },
});

const RichtextDisplay: React.FC<RichtextDisplayProps> = ({ richText, className }) => {
  const textHtml = render(richText as StoryblokRichTextNode) as string;

  return (
    <div className={className}>
      <div className='storyblok-richtext-default' dangerouslySetInnerHTML={{ __html: textHtml }}></div>
    </div>
  );
};

export default RichtextDisplay;
