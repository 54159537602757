import { MeasureStoryblok } from '@maivenenergy/storyblok';
import { StoryblokComponent } from '@storyblok/react';

interface MeasureProps {
  blok: MeasureStoryblok;
}

const Measure = ({ blok }: MeasureProps) => {
  return (
    <div className='flex flex-col gap-2'>
      {blok?.category?.map((category) => {
        return typeof category === 'string' ? null : <StoryblokComponent blok={category.content} key={category.uuid} />;
      })}
      {blok?.subcategory?.map((subc) => {
        return typeof subc === 'string' ? null : <StoryblokComponent blok={subc.content} key={subc.uuid} />;
      })}
    </div>
  );
};

export default Measure;
