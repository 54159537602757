import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

// import AssessmentFormNavigationIcon from './AssessmentFormNavigationIcon';
import AssessmentFormSection from './AssessmentFormSection';
import { useAssessmentFormSections } from './hooks/useAssessmentFormSections';
import { OAUTH_REDIRECT_PARAM } from './ShareUsageData';

import FullPageLayout from '@/components/Layout/FullPageLayout';
import Select, { SelectItem } from '@/components/Select';
import { useUserProfileContext } from '@/contexts/UserProfileContext';
import AssessmentFormHeader from '@/pages/Assessment/components/AssessmentFormHeader';

const AssessmentForm = () => {
  const { profile } = useUserProfileContext();
  const { currentSection, sectionNames, sections, setCurrentSection } = useAssessmentFormSections(profile);
  const [searchParams, setSearchParams] = useSearchParams();

  const selectItems = useMemo(
    () =>
      sectionNames.map<SelectItem>((step) => ({
        label: `${sections[step.id]?.idx} ${sections[step?.id]?.title}`,
        value: step?.id.toString(),
        // initialElement: <AssessmentFormNavigationIcon status={step?.status} />,
      })),
    [sectionNames, sections]
  );

  useEffect(() => {
    const currentSectionSearchParam = searchParams.get('currentSection');
    const oAuthRedirectParam = searchParams.get(OAUTH_REDIRECT_PARAM);

    if (currentSectionSearchParam && oAuthRedirectParam === 'true') {
      setCurrentSection(currentSectionSearchParam);
      setSearchParams((current) => {
        current.delete(OAUTH_REDIRECT_PARAM);
        current.delete('currentSection');
        return current;
      });
      return;
    }
    if (currentSectionSearchParam) {
      setCurrentSection(currentSectionSearchParam);
      setSearchParams((current) => {
        current.delete('currentSection');
        return current;
      });
    }
  }, [searchParams, setCurrentSection, setSearchParams]);

  return (
    <FullPageLayout className='mx-auto bg-white lg:flex lg:min-h-dvh lg:flex-col'>
      <div className='sticky left-0 top-0 z-10 flex flex-col bg-white'>
        <AssessmentFormHeader progress={sectionNames.reduce((a, c) => a + c.percentage, 0) / sectionNames.length} />
        <div className='mx-auto w-full py-4 sm:px-4 md:max-w-2xl lg:max-w-3xl'>
          <Select
            data-id='section-select'
            name='section-select'
            key={`${currentSection}-select`}
            onChange={(value) => {
              setCurrentSection(value);
            }}
            value={currentSection ?? ''}
            items={selectItems}
          />
        </div>
      </div>

      <div className='mx-auto flex w-full flex-col sm:px-4 md:max-w-2xl lg:max-w-3xl'>
        {!!currentSection && !!sections[currentSection] && (
          <AssessmentFormSection
            key={sections[currentSection].title}
            currentSection={sections[currentSection]}
            canNextSection={sections[currentSection].canNextSection}
            canPrevSection={sections[currentSection].canPrevSection}
          />
        )}
      </div>
    </FullPageLayout>
  );
};

export default AssessmentForm;
