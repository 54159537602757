import { BoltIcon, CubeTransparentIcon, CurrencyDollarIcon } from '@heroicons/react/24/solid';
import { EnergyUsageItemStoryblok } from '@maivenenergy/storyblok';

import Text from '@/components/Typography';

interface EnergyUsageItemProps {
  blok: EnergyUsageItemStoryblok;
}

const EnergyUsageItem = ({ blok }: EnergyUsageItemProps) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const valueNumber = parseInt(blok.value ?? '0');
  const formattedValue = formatter.format(valueNumber);
  return (
    <div className='flex flex-1 flex-col gap-3'>
      <div className='flex flex-col gap-2'>
        {blok.icon === 'bolt' && <BoltIcon className='w-4' />}
        {blok.icon === 'cube-transparent' && <CubeTransparentIcon className='w-4' />}
        {blok.icon === 'currency-dollar' && <CurrencyDollarIcon className='w-4' />}
        <Text className='text-xs font-bold leading-none'>{blok.title}</Text>
      </div>
      <div className='flex flex-col gap-2'>
        <Text className='text-sm font-bold leading-none'>
          {blok.icon === 'currency-dollar' ? formattedValue : Math.round(valueNumber * 100) / 100}
        </Text>
        <Text className='text-xs leading-none text-gray-500'>{blok.description}</Text>
      </div>
    </div>
  );
};

export default EnergyUsageItem;
