import { ReportSummaryBlockStoryblok } from '@maivenenergy/storyblok';
import { StoryblokComponent } from '@storyblok/react';

interface ReportSummaryBlockProps {
  blok: ReportSummaryBlockStoryblok;
}

const ReportSummaryBlock = ({ blok }: ReportSummaryBlockProps) => {
  return (
    <div className='flex flex-col gap-4 p-6'>
      {blok.items?.map((item) => {
        return typeof item === 'string' ? null : <StoryblokComponent blok={item} key={item._uid} />;
      })}
    </div>
  );
};

export default ReportSummaryBlock;
