import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import Text from '@/components/Typography';

interface ImageTextLinkProps {
  image: string;
  text: string;
  link?: string;
  className?: string;
  textClassName?: string;
  imageClassName?: string;
}

const ImageTextLink: React.FC<ImageTextLinkProps> = ({
  image,
  text,
  className,
  link,
  textClassName,
  imageClassName,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`flex cursor-pointer items-center justify-start overflow-hidden rounded-xl border border-gray-200 bg-white p-2 hover:bg-gray-100 ${
        className ?? ''
      }`}
      onClick={() => {
        if (link) navigate(link);
      }}
    >
      <img
        src={image}
        alt='model-display-image'
        className={`w-[50px] overflow-hidden rounded ${imageClassName ?? ''}`}
      />
      <Text className={`p-2 text-sm ${textClassName ?? ''}`}>{text}</Text>
      <div className='ml-auto p-2'>
        <ChevronRightIcon className='size-4' />
      </div>
    </div>
  );
};

export default ImageTextLink;
