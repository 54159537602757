import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

import Constants from '@/lib/Constants';

export const useApiClient = () => {
  const { getAccessTokenSilently } = useAuth0();

  const client = axios.create({
    baseURL: `https://${import.meta.env.VITE_BACKEND_DOMAIN}`,
  });

  return {
    getClient: async () => {
      const token = await getAccessTokenSilently();

      client.interceptors.request.use((request) => {
        request.headers.set(Constants.headerKeys.bearerToken, `Bearer ${token}`);

        return request;
      });

      return client;
    },
  };
};
