import React, { FC } from 'react';

import SVGIcon, { SVGIconProps } from '@/components/SVGIcon';

const MaivenAvatarGradientDark: FC<SVGIconProps> = ({ width = '48', height = '48', viewBox = '0 0 48 48' }) => {
  return (
    <SVGIcon width={width} height={height} viewBox={viewBox}>
      <rect width='48' height='48' rx='11' fill='url(#paint0_linear_213_2968)' />
      <path
        d='M23.9996 8L8 18.0644V27.9991C9.46848 25.34 10.8213 22.998 12.104 21.1787C12.9957 19.9141 13.8013 18.9792 14.568 18.319C15.5898 17.4397 16.577 17.0123 17.5878 17.0123C18.4761 17.0123 19.253 17.1969 19.8956 17.5611C20.5433 17.9279 21.0533 18.47 21.4105 19.1731C22.016 20.3635 22.0979 21.8827 22.0979 23.2105L22.0135 23.2138H22.0979C22.0979 23.4937 22.0962 23.7635 22.0937 24.0223C22.0852 24.943 22.0776 25.7329 22.2127 26.3753C22.293 26.7581 22.3884 26.8828 22.4078 26.9048C22.4247 26.9107 22.4737 26.9225 22.5708 26.9225C22.8976 26.9225 23.2742 26.6249 23.7201 26.0111C24.2664 25.2608 24.7663 24.2348 25.3465 23.0478L25.4495 22.8362C25.9806 21.7486 26.7077 20.2598 27.6096 19.1393C28.7445 17.7281 30.0669 17.0131 31.5396 17.0131C32.4136 17.0131 33.1845 17.25 33.8314 17.7171C34.3955 18.1243 34.843 18.6959 35.1622 19.415C35.587 20.3719 35.793 21.6146 35.793 23.213V30.1851H32.6956V23.213C32.6956 22.0707 32.5723 21.2141 32.33 20.6678C32.2354 20.4545 32.129 20.3045 32.015 20.2218C31.9753 20.1932 31.8537 20.1046 31.5396 20.1046C31.0321 20.1046 30.5642 20.4039 30.0246 21.075C29.393 21.8607 28.8171 22.9972 28.2344 24.191L28.0968 24.4734C27.5547 25.5862 26.9408 26.8474 26.2272 27.8288C25.1565 29.299 23.9607 30.0148 22.5716 30.0148C21.6005 30.0148 20.7654 29.6734 20.1557 29.0267C19.6794 28.5217 19.3611 27.8625 19.1821 27.0101C18.9701 26.0035 18.9853 24.7912 18.9963 23.8175C18.9988 23.6 19.0013 23.3985 19.0013 23.2164C19.0013 21.8835 18.8899 21.0447 18.6492 20.5725C18.5462 20.3702 18.4449 20.2943 18.368 20.2513C18.2506 20.1847 18.0201 20.1055 17.5886 20.1055C17.5067 20.1055 17.1909 20.146 16.5913 20.6619C16.0281 21.1467 15.3711 21.9198 14.6381 22.9592C13.2963 24.8629 11.7873 27.5362 10.356 30.1421L10.3323 30.1859H8V34.4223H23.7158L32.639 40V34.4223H40V18.0644L23.9996 8Z'
        fill='#030712'
      />
      <defs>
        <linearGradient
          id='paint0_linear_213_2968'
          x1='28.3246'
          y1='-8.20798'
          x2='-2.97648'
          y2='51.6823'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.200009' stopColor='#FDBA25' />
          <stop offset='0.500009' stopColor='#F49E87' />
          <stop offset='0.835009' stopColor='#9747FF' />
        </linearGradient>
      </defs>
    </SVGIcon>
  );
};

export default MaivenAvatarGradientDark;
