import { useEffect } from 'react';

import { useAuthContext } from '@/contexts/AuthContext';

const Logout = () => {
  const { handleLogout } = useAuthContext();

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return null;
};

export default Logout;
